import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { PredictActions } from "../../modules/validate/ducks/index";
import { bindActionCreators } from "redux";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import TranslateMessage from "../translateManager/TranslateMessage";
import YouTube from 'react-youtube';
import {
    EditViewModal,
    ValidateModal,
} from '../../util/Constants';
import '../../App.css';
import Faq from "react-faq-component";


const HelpModal = ({ showModal, closeModal, validateStatus }) => {

    const opts = {
        height: '250',
        width: '500',
        playerVars: {
            autoplay: 1,
        },
    };

    const data = {
        title: "Frequently Asked Questions",
        rows: [
            {
                title: "Where can I see my remaining pages for this month/year?",
                content: `At the navigation bar on the left is a Blue dot with the first letter of your email and below the remaining pages.
                The paid plans are showing the pages monthly or yearly based on the package you chose. To find it out go to Account -> My Plan.`,
            },
            {
                title: "What is the use of the Select page?",
                content: `On the left you find your uploaded document. The document contains colored frames. 
                Each box represents a found entry seen on the right side in the Text/Table section. These entries can be selected by clicking the checkboxes for download.`,
            },
            {
                title: "Why do I need to select data?",
                content: "As we do not now the kind of data you are uploading, you need to select the data you want to extract from the Text/Table entries using the checkboxes.",
            },
            {
                title: "How do I select data?",
                content: `You select the data (Values Column) you want to extract by clicking the checkboxes or using the Dropdown menu to assign the correct label. 
                The Labels will be shown as the Column Header of the result file.<br>
                In the Selection column you need to choose which Values you want to extract.<br>
                The Labels column shows the automatic classification of the Values.<br>
                The Values column shows the extracted content. Values with the same Labels need to be selected only once.`,
            },
            {
                title: "Do I need to select all pages?",
                content: `No, based on your selections we auto-select all following pages. Please validate all pages to make sure you get all data needed.`,
            },
            {
                title: "What will be shown in the results file?",
                content: `Open the Preview to see the current selection. Only selected (checkbox) Values will be shown in the results file. Therefore, you need to select the data to be extracted. The Values will have the Labels as their Column Headers in the result. 
                `,
            },
            {
                title: "What to do if there are similar Labels like e.g. ‘Telephone’ and ‘Phone’?",
                content: `Select the first entry having the Label ‘Telephone’. Now the Dropdown menu contains the Label ‘Telephone’. 
                For the entry having the Label ‘Phone’ you can use the Dropdown menu to assign the Label ‘Telephone’. 
                All 'Phone' Values will now be auto-selected as 'Telephone'.`,
            },
            {
                title: "Can I reuse my selections?",
                content: `In the step ‘Download’ you have the option to save your selections. This way you can reuse your selections and save time for similar documents.`,
            },
            {
                title: "Can I Edit Frames (left side) and the Cell in the Text/Table sections (right side)?",
                content: `Yes of course. You are always in full control and can edit, delete or draw new frames by switching to Edit mode. 
                You can also click on any field in the Text/Table section to directly edit the content. 
                To edit the Table Header row you must first de-select the 1st Row is a Header.`,
            },
            {
                title: "How do I draw a new Table?",
                content: `First, draw a Table Box by clicking and holding the mouse. Next, draw Columns by clicking the + Icon on the right side of the drawn Table. 
                Move the Column lines in place and click on 'Confirm Table' at the bottom.`,
            },
            {
                title: "What is 1st row is Header?",
                content: `It defines that the 1st row contains the Header of the Table and the body of the table starts from the second row. 
                Besides, it helps with auto-selection.`,
            },
            {
                title: "What is Transpose?",
                content: `docutize.ai only selects horizontally. 
                Therefore, this function flips the Table to allow for selecting Tables containing the header in the first column instead of the first row.`,
            },
        ],
    };
    const styles = {
        // bgColor: 'white',
        titleTextSize: '18px',
        titleTextColor: 'black',
        rowTitleTextSize: 'medium',
        rowContentTextSize: 'medium',
        rowTitleColor: 'black',
        rowContentColor: 'grey',
    };

    const config = {
        animate: true,
        arrowIcon: "V",
        openOnload: 0,
        expandIcon: "+",
        collapseIcon: "-",
    };


    const handleClose = () => {
        closeModal(false);
    }

    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    const dispatch = useDispatch();
    const handleButtonClick = () => {
        console.log("handleButtonClick");
        dispatch(PredictActions.clickProductTour());
    };

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus={true}
        >
            <Box
                className="help-modal"
                style={{ maxHeight: "90%", overflowY: "auto" }}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                    sx={{
                        position: "relative",
                        top: '-2%',
                        left: '95%',
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Typography id="modal-modal-description">
                    <div className="btn-align-center">
                        <Button className="blue-btn-4" sx={{ width: '250px', marginBottom: '20px' }} onClick={handleButtonClick}>
                            Start Product Tour
                        </Button>
                    </div>

                    <div>
                        <YouTube videoId={validateStatus.isEditMode ? EditViewModal.VIDEO_ID : ValidateModal.VIDEO_ID} opts={opts} onReady={_onReady} />
                    </div>

                </Typography>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 20,
                }}>
                    <TranslateMessage translateKey={validateStatus.isEditMode ? EditViewModal.DESCRIPTION_KEY : ValidateModal.DESCRIPTION_KEY} />
                </div>
                <div style={{ paddingTop: 20 }}>
                    <Faq
                        data={data}
                        styles={styles}
                        config={config}
                    />
                </div>
            </Box>
        </Modal>
    );
}
function mapStateToProps(state) {
    return {
        addRating: state.Predict.addRating,
        validateStatus: state.Predict.validateStatus,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        predictActions: bindActionCreators(PredictActions, dispatch),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HelpModal)
);