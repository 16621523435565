export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const KONVA_PIXEL_RATIO = 12;

export const SUBSCRIPTION = {
  SUCCESS_URL: process.env.REACT_APP_SUBSCRIPTION_SUCCESS_URL,
  CANCEL_URL: process.env.REACT_APP_SUBSCRIPTION_CANCEL_URL,
}

export class DrawingMode {
  static KEY_VALE = "DRAW_TYPE_KEY_VALE";
  static COLUMN = "DRAW_TYPE_COLUMN";
  static TABLE = "DRAW_TYPE_TABLE";
}

export class ColorTheme {
  static KEY_VALUE_CLR = "#FF0000";
  static TABLE_CLR = "#77C3EC";
  static TABLE_CELL_CLR = "#ffea61";
  static SELECTED_TABLE_CELL_CLR = "#a8a8a8";
  static COLUMN_CLR = "#fffd96";
  static TABLE_EDIT_MODE_CLR = "yellow";
}

export class AppMode {
  static MOVE_ACTION = "MOVE";
  static DRAWING_ACTION = "DRAWING";
  static DRAG_ACTION = "DRAG";
}

export class ElementType {
  static ELM_KEY_VAL = "keyValuePair";
  static ELM_TABLE = "table";
  static ELM_CELL = "cell";
  static ELM_COLUMN = "Column";
}

export class ViewMode {
  static EDIT_VIEW = "EDIT_VIEW";
  static DEFAULT_VIEW = "DEFAULT_VIEW";
}

export class ElmOperation {
  static UPDATED = "updated";
  static DELETED = "deleted";
  static CREATED = "created";
}

export class ValidateModal {
  static HEADING_KEY = "validate_modal_header";
  static VIDEO_ID = "vJsQs3zVyaM";
  static DESCRIPTION_KEY = "validate_modal_description";
}

export class DownloadModal {
  static HEADING_KEY = "download_modal_header";
  static VIDEO_ID = "vJsQs3zVyaM";
  static DESCRIPTION_KEY = "download_modal_description";
}

export class DownloadOpeningModal {
  static HEADING_KEY = "download_opening_modal_header";
  static VIDEO_ID = "vJsQs3zVyaM";
  static DESCRIPTION_KEY = "download_opening_modal_description";
}

export class EditViewModal {
  static HEADING_KEY = "edit_view_modal_header";
  static VIDEO_ID = "vJsQs3zVyaM";
  static DESCRIPTION_KEY = "edit_view_modal_description";
}

export class YoutubeVideo {
  static UPLOAD_LOADING_VIDEO = "vJsQs3zVyaM";
}

export class CanvasOrientation {
  static VERTICAL = "VERTICAL";
  static HORIZONTAL = "HORIZONTAL";
}

export const UNUSED = "-";

export const MAX_PAGES_PER_UPLOAD = 100;

export const DEFAULT_SIMILARITY_VALUE = 90;

export const IS_ENABLE_SIGN_UP =
  process.env.REACT_APP_IS_ENABLE_SIGN_UP === "ENABLED";

export const SIGN_UP_URL = process.env.REACT_APP_SIGN_UP_URL;

export const PDF_UPLOAD = "PDF_UPLOAD";

export const FILE_TYPE = "FILE_TYPE";
export const FILE_TYPE_MS_EXCEL = 1;
export const FILE_TYPE_COMMA_SEP = 2;
export const FILE_TYPE_TEXT_FILE = 3;

export const ORDERING_VALUE_NULL = '-';
export const ORDERING_VALUE_NONE = '-';

export const RESULT_TYPE = "RESULT_TYPE";
export const RESULT_TYPE_ALL = 1;
export const RESULT_ZIP_SINGLE = 2;
export const RESULT_ZIP_ALL = 3;

export const FORMATING = "FORMATING";
export const FORMATING_NO_EXTRA_INFO = 1;
export const FORMATING_INCLUDE_PAGE_NUM = 2;
export const FORMATING_INCLUDE_FILE_NAME = 3;

export const MERGE_TYPE = "MERGE_TYPE";
export const MERGE_INTELLIGENTLY = 1;
export const MERGE_EACH_PAGE = 2;
export const MERGE_INTELLIGENTLY_EACH_PAGE = 3;

export const RESULT = "RESULT";
export const OVERALL_EXP = "OVERALL_EXP";
export const HANDLING = "HANDLING";

export const COLOR_GRADIENT_STARTS = 30;

export const RESET_PASSWORD_REDIRECT_TIMEOUT = 5000;

export const TUTORIAL_YOUTUBE = "https://www.youtube.com/watch?v=vJsQs3zVyaM";

export const DEFAULT_1_ST_LANGUAGE = "eng";
export const LANGUAGE_OPTIONS = [
  {
    key: "eng",
    label: "English",
  },
  {
    key: "deu",
    label: "German",
  },
  {
    key: "afr",
    label: "Afrikaans",
  },
  {
    key: "cat",
    label: "Catalan",
  },
  {
    key: "ces",
    label: "Czech",
  },
  {
    key: "dan",
    label: "Danish",
  },
  {
    key: "est",
    label: "Estonian",
  },
  {
    key: "fin",
    label: "Finnish",
  },
  {
    key: "fra",
    label: "French",
  },
  {
    key: "hrv",
    label: "Croatian",
  },
  {
    key: "hun",
    label: "Hungarian",
  },
  {
    key: "isl",
    label: "Icelandic",
  },
  {
    key: "ita",
    label: "Italian",
  },
  {
    key: "lav",
    label: "Latvian",
  },
  {
    key: "lit",
    label: "Lithuanian",
  },
  {
    key: "ltz",
    label: "Luxembourgish",
  },
  {
    key: "nld",
    label: "Dutch",
  },
  {
    key: "nor",
    label: "Norwegian",
  },
  {
    key: "pol",
    label: "Polish",
  },
  {
    key: "por",
    label: "Portuguese",
  },
  {
    key: "ron",
    label: "Romanian",
  },
  {
    key: "slk",
    label: "Slovak",
  },
  {
    key: "slv",
    label: "Slovenian",
  },
  {
    key: "spa",
    label: "Spanish",
  },
  {
    key: "swe",
    label: "Swedish",
  },
  {
    key: "tur",
    label: "Turkish",
  }
];
