import React, { useState, useRef, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useTable, usePagination } from 'react-table';
import { PredictActions } from '../../modules/validate/ducks';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import ToolTip from '../shared/ToolTip';
import TranslateMessage from '../../components/translateManager/TranslateMessage';

import CssBaseline from '@mui/material/CssBaseline';
import { ElementType, UNUSED, ORDERING_VALUE_NULL } from '../../util/Constants';
import { CheckedCheckBoxIcon, EmptyCheckBoxIcon } from '../../components/shared/CustomComponents';

const Styles = styled.div`
  padding-top: 10px;
  margin-right: 5px;

  input {
    font-size: 13px;
    border: none;
    width: 100%;
  }

  .pagination {
    padding: 13px;
  }
`;

const valStyle = { color: 'black', fontSize: '13px' };
const inputStyle = {
  width: '100%',
  border: '1px solid #ced4da',
  borderRadius: '3px',
};

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const [isValEdit, setValEdit] = useState(false);
  const valVal = useRef(value);
  const [editRows, setEditRows] = useState(1);

  const changeValEdit = () => {
    setValEdit(!isValEdit);
  };

  const onChange = value => {
    setValue(value);
  };

  useEffect(() => {
    const rows = valVal.current.scrollHeight / 18
    setEditRows(rows);
  }, [value])


  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const updateValFocusOut = () => {
    setValEdit(false);
    if (value !== valVal.current.value) {
      setValue(valVal.current.value);
      onChange(valVal.current.value);
      updateMyData(index, id, valVal.current.value);
    }
  };

  const updateVal = event => {
    if (event.key === 'Enter') {
      setValEdit(false);
      setValue(valVal.current.value);
      onChange(valVal.current.value);
      updateMyData(index, id, valVal.current.value);
    }
  };

  const textAreaAdjust = e => {
    setEditRows((e.target.scrollHeight / 20).toFixed(0));
  };

  const renderValEditView = () => {
    return (
      <div className="p-0">
        <textarea
          style={{ ...valStyle, ...inputStyle }}
          type="text"
          rows={editRows}
          defaultValue={value}
          ref={valVal}
          onFocus={textAreaAdjust}
          onKeyDown={e => {
            updateVal(e);
          }}
          onBlur={updateValFocusOut}
        />
      </div>
    );
  };

  return renderValEditView();
  // return isValEdit ? renderValEditView() : renderValDefaultView();

};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({
  columns,
  data,
  updateMyData,
  skipPageReset,
  getTableHeadings,
  tableColumnChange,
  updatedColHeadings,
  hasHeading,
  addOderingValueTable,
  setSelectedItem,
  pending,
  deSelectPending
}) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, setPageSize } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        // use the skipPageReset option to disable page resetting temporarily
        autoResetPage: !skipPageReset,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        updateMyData,
      },
      usePagination,
    );

  const [editRows, setEditRows] = useState(1);
  const [columnLabel, setColumnLabel] = useState(null);
  const [displayField, setDisplayField] = useState(-1);

  const textareaRef = useRef(null);

  const updateKey = (event, index) => {
    if (event.key === 'Enter') {
      setDisplayField(-1);
      let orderingValueTableDto = addOderingValueTable(updatedColHeadings[index].id, event.target.value, index);
      tableColumnChange(orderingValueTableDto?.columnHeaderId, orderingValueTableDto?.heading, orderingValueTableDto?.index);
    }
  };

  const getColumnLabel = index => {
    try {
      if (
        headerGroups &&
        headerGroups[0] &&
        headerGroups[0].headers &&
        headerGroups[0].headers[index]
      ) {
        const headerValue = headerGroups[0].headers[index].Header;
        return headerValue;
      } else {
        return undefined;
      }
    } catch (error) {
      // Handle any exceptions that occur in the try block
      return undefined;
    }
  };

  const keyStyle = {
    color: 'grey',
    fontSize: '13px',
    fontWeight: '600',
    display: 'inline',
    wordBreak: 'break-word',
  };

  const inputStyle = {
    border: '1px solid #ced4da',
    borderRadius: '3px',
    width: '98%',
    marginLeft: '13%',
  };

  const textAreaAdjust = e => {
    setEditRows((e.target.scrollHeight / 20).toFixed(0));
  };

  const checkboxHandler = (columnHeaderId, value, index) => {
    if (keyIsSelected(index)) {
      tableColumnChange(updatedColHeadings[index].id, ORDERING_VALUE_NULL, index);
    } else {
      if (hasHeading) {
        const column_label = getColumnLabel(index);
        if (column_label) {
          let orderingValueTableDto = addOderingValueTable(updatedColHeadings[index].id, column_label, index);
          tableColumnChange(orderingValueTableDto?.columnHeaderId, orderingValueTableDto?.heading, orderingValueTableDto?.index);

        } else {
          setDisplayField(index);
        }
      } else {
        setDisplayField(index);
      }

      setSelectedItem();
    }
  };

  const keyIsSelected = index => {
    return updatedColHeadings[index].value !== undefined &&
      updatedColHeadings[index].value !== '' &&
      updatedColHeadings[index].value !== '-' &&
      updatedColHeadings[index].value !== 'unselected'
      ? true
      : false;
  };

  useEffect(() => {
    setPageSize(1000);
  }, []);
  // Render the UI for your tables
  return (
    <>
      <MuiTable {...getTableProps()}>
        <TableHead style={{ fontWeight: 600 }}>
          <TableRow>
            {updatedColHeadings.map((table, index) => {
              return (
                <TableCell
                  key={`tableHead-${index}`}
                  style={{
                    fontWeight: 600,
                    color: 'grey',
                    fontSize: '13px',
                    fontFamily: 'Poppins',
                    border: 'none',
                    padding: '0px 20px 0px 0px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      padding: '0px',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <ToolTip title="Select the Box to extract the Column" imgUrl={null} isBtn={true}>
                      {(pending && updatedColHeadings[index]?.showLoading )|| (deSelectPending && updatedColHeadings[index]?.deselected) ? (
                        <div className="loading-square-container">
                          <div className="spinner" />
                        </div>
                      ) : (
                        <Checkbox
                          disabled={pending || deSelectPending}
                          onChange={() => {
                            checkboxHandler(updatedColHeadings[index].id, undefined, index);
                          }}
                          checked={keyIsSelected(index)}
                          icon={<EmptyCheckBoxIcon />}
                          checkedIcon={<CheckedCheckBoxIcon />}
                          style={{
                            paddingRight: '5px',
                          }}
                          sx={{
                            '&.Mui-disabled': {
                              opacity: pending || deSelectPending ? 0.4 : 1,
                            },
                            '&.Mui-checked': {
                              color: 'black',
                            },
                          }}
                        />
                      )}
                    </ToolTip>
                    <select
                      name="tabelView"
                      id="tabelView"
                      className="form-select form-select-sm"
                      value={updatedColHeadings[index].value}
                      onChange={e =>
                        tableColumnChange(updatedColHeadings[index].id, e.target.value, index)
                      }
                    >
                      <option value={UNUSED}>{UNUSED}</option>
                      {getTableHeadings.data.map(heading => {
                        return <option value={heading}>{heading}</option>;
                      })}
                    </select>
                  </div>
                  {displayField === index && (
                    <div className="error-msg-table-selection mt-1">
                      {<TranslateMessage translateKey="please_add_title" />}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            {updatedColHeadings.map((table, index) => {
              return (
                <TableCell
                  style={{
                    fontWeight: 600,
                    color: 'grey',
                    fontSize: '13px',
                    fontFamily: 'Poppins',
                    border: 'none',
                    padding: '0px 13px 0px',
                  }}
                >
                  <div style={{ display: 'flex', verticalAlign: 'center' }}>
                    {displayField === index && (
                      <div className="pe-3">
                        <textarea
                          style={{ ...keyStyle, ...inputStyle }}
                          type="text"
                          rows={editRows}
                          ref={textareaRef}
                          onFocus={textAreaAdjust}
                          onChange={e => {
                            setColumnLabel(e.target.value);
                          }}
                          onKeyDown={e => {
                            updateKey(e, index);
                          }}
                          onBlur={() => {
                            setDisplayField(-1);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>

          {hasHeading &&
            headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...column.getHeaderProps()}
                    style={{
                      fontWeight: 600,
                      color: 'grey',
                      fontSize: '13px',
                      fontFamily: 'Poppins',
                      padding: '0px 13px 10px',
                    }}
                  >
                    <span>{column.render('Header')}</span>
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableHead>
        <TableBody {...getTableBodyProps()} style={{ fontWeight: 600, fontSize: '10px' }}>
          {page?.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <TableCell
                      key={`tableCell2-${i}`}
                      {...cell.getCellProps()}
                      style={{
                        fontSize: '13px',
                        padding: '10px 13px',
                        fontFamily: 'Poppins',
                      }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </>
  );
}

function TableView({
  selectedTable,
  updateTableHandler,
  updateRow,
  page,
  selectedTableNo,
  getTableHeadings,
  tableColumnChange,
  updatedColHeadings,
  addOrderingValue,
  addOderingValueTable,
  predictActions,
  pending,
  deSelectPending
}) {
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [tableColumnModel, setTableColumnModel] = useState([]);
  const [tableData, setTableData] = useState([]);
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);

    let tableColumns = [];
    if (selectedTable?.transposed) {
      tableColumns = selectedTable?.transposed_columns;
    } else {
      tableColumns = selectedTable.columns;
    }

    setTableData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          let oldCol = null;

          let colIndex = tableColumns.findIndex(c => c.title === columnId);
          if (colIndex === -1) {
            oldCol = old[rowIndex].cell[columnId];
          } else {
            oldCol = old[rowIndex].cell[colIndex];
          }

          let dataDto = {
            rowId: oldCol?.row_id,
            pageId: page?.data?.id,
            tableId: selectedTable.id,
            cell: {
              id: oldCol?.id,
              value: value,
            },
          };

          updateRow({ dataDto: dataDto });
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const setSelectedItem = () => {
    predictActions.changeValidateStatus({
      isSelectItem: true,
    });
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    // ******************************************************************************************
    // API call for send edited table data
    // ******************************************************************************************
    setSkipPageReset(false);
    updateTableHandler(tableData);
  }, [tableData]);

  React.useEffect(() => {
    let rows = [];
    let columnsModel = [];
    let tableRows = [];
    let tableColumns = [];

    if (selectedTable?.transposed) {
      tableRows = selectedTable?.transposed_rows;
      tableColumns = selectedTable?.transposed_columns;
    } else {
      tableRows = selectedTable?.rows;
      tableColumns = selectedTable?.columns;
    }

    tableColumns?.map((cell, j) => {
      let value = selectedTable.title_exist ? cell.title : j.toString();

      let columnModelObj = {
        Header: value,
        accessor: j.toString(),
      };

      columnsModel.push(columnModelObj);
      return cell;
    });

    tableRows?.map((row, i) => {
      if (!(selectedTable?.title_exist && i === 0)) {
        if (row.title === false) {
          let rowObj = {};
          let cellList = [];
          row.cells.map((cell, j) => {
            cellList.push(cell);
            rowObj[j.toString()] = cell.value;
            return cell;
          });
          rowObj[ElementType.ELM_CELL] = cellList;
          rows.push(rowObj);
        }
      }
      return row;
    });
    setTableColumnModel(columnsModel);
    setTableData(rows);
  }, [selectedTable]);

  return (
    <Styles>
      <CssBaseline />

      <Table
        columns={tableColumnModel}
        data={tableData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        // pageObj={page}
        selectedTableNo={selectedTableNo}
        getTableHeadings={getTableHeadings}
        tableColumnChange={tableColumnChange}
        updateTableHandler={updateTableHandler}
        updatedColHeadings={updatedColHeadings}
        hasHeading={selectedTable !== null ? selectedTable?.title_exist : null}
        addOrderingValue={addOrderingValue}
        addOderingValueTable={addOderingValueTable}
        setSelectedItem={setSelectedItem}
        pending={pending}
        deSelectPending={deSelectPending}
      />
    </Styles>
  );
}

function mapStateToProps(state) {
  return {
    selectedTableProps: state.Predict.selectedTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    predictActions: bindActionCreators(PredictActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TableView));
