import React, { Component } from "react";
import Button from "@mui/material/Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthActions } from "../auth/ducks";
import countries from "../../data/Country";
import TranslateMessage from "../../components/translateManager/TranslateMessage";
import companySizes from "../../data/CompanySizes";

/**
 * 
 *
 * @component
 * @example
 * // Usage example:
 * <Account />
 *
 * @returns {JSX.Element} The JSX element representing the Account component.
 *
 */
class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readonlyInput: null,
      getUserInfo: null,
      address: "",
      city: "",
      company: "",
      country: "",
      email: "",
      firstName: "",
      lastName: "",
      postalCode: "",
      state: "",
      companySize: "",
      website: "",
      isValidCompanySize: true,
      isValidCountry: true,
      isValidWebsite: true,
    };
  }

  /**
   * Runs getting user info service at component mounting
   */
  componentDidMount() {
    this.props.AuthActions.getUserInfo();
  }


  /**
   * componentDidUpdate lifecycle method.
   *
   * @method
   * @param {Object} prevProps - The previous props object.
   * @param {Object} prevState - The previous state object.
   *
   * @description
   * The `componentDidUpdate` lifecycle method is called whenever the component's props or state are updated after a re-render.
   * It compares the current `getUserInfo` prop data with the previous state's `getUserInfo` data and updates the component's state accordingly.
   */
  componentDidUpdate(prevProps, prevState) {
    const { getUserInfo } = this.props;
    if (
      getUserInfo &&
      getUserInfo.data &&
      getUserInfo.data.user &&
      JSON.stringify(getUserInfo.data.user) !== JSON.stringify(prevState.getUserInfo)
    ) {
      this.setState({
        getUserInfo: getUserInfo.data.user,
        address: getUserInfo.data.user.address ? getUserInfo.data.user.address : "",
        city: getUserInfo.data.user.city ? getUserInfo.data.user.city : "",
        company: getUserInfo.data.user.company ? getUserInfo.data.user.company : "",
        country: getUserInfo.data.user.country ? getUserInfo.data.user.country : "",
        email: getUserInfo.data.user.email ? getUserInfo.data.user.email : "",
        firstName: getUserInfo.data.user.firstName ? getUserInfo.data.user.firstName : "",
        lastName: getUserInfo.data.user.lastName ? getUserInfo.data.user.lastName : "",
        postalCode: getUserInfo.data.user.postalCode
          ? getUserInfo.data.user.postalCode
          : "",
        state: getUserInfo.data.user.state ? getUserInfo.data.user.state : "",
        tax_vat: getUserInfo.data.user.tax_vat ? getUserInfo.data.user.tax_vat : "",
        website: getUserInfo.data.user.website ? getUserInfo.data.user.website : "",
        companySize: getUserInfo.data.user.companySize
          ? getUserInfo.data.user.companySize
          : "",
      });
    }
  }

  /**
   * Make Input Editable.
   *
   * 
   * @function
   * @param {any} data - The data associated with the input.
   * @param {string} inputId - The unique identifier of the input to be made editable.
   *
   * @description
   * The `makeEditable` function is used to make a specific input element editable within the component's state.
   * It takes the `data` associated with the input and the `inputId` of the input element as parameters.
   */
  makeEditable = (data, inputId) => {
    this.setState({
      readonlyInput: inputId,
    });
  };

  /**
   * Capitalizes the first letter.
   * 
   * @function
   * @param {string} string 
   * @returns the same string after capitalizing the first letter
   */
  capitalizeFirstLetter = (string) => {
    const str = string;
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };

  /**
   * Input change handler
   * 
   * @function
   * @param {string} input user input
   * @param {string} field field name
   * 
   * @description This method maintains the state according to the input changes.
   */
  inputChangeHandler = (input, field) => {
    if (field === "companySize") {
      if (this.validateCompanySize(input)) {
        this.setState({
          [field]: input,
          isValidCompanySize: true,
        });
      } else {
        this.setState({
          [field]: input,
          isValidCompanySize: false,
        });
      }
    } else if (field === "country") {
      if (this.validateCountry(input)) {
        this.setState({
          [field]: input,
          isValidCountry: true,
        });
      } else {
        this.setState({
          [field]: input,
          isValidCountry: false,
        });
      }
    } else if (field === "website") {
      if (this.validateCompanyURL(input)) {
        this.setState({
          [field]: input,
          isValidWebsite: true,
        });
      } else {
        this.setState({
          [field]: input,
          isValidWebsite: false,
        });
      }
    } else {
      this.setState({
        [field]: input,
      });
    }
  };





  /**
   * 
   * @function
   * 
   * @param {string} companySize
   * @returns {boolean} whether the company size is valid
   * 
   * @description Validate Company size
   */
  validateCompanySize = (companySize) => {
    return true
  };

  /**
   * Validate Country
   * 
   * @function
   * 
   * @param {string} country
   * @returns {boolean}
   */
  validateCountry = (country) => {
    return true;
  };

  /**
   * Validate company URL
   * 
   * @function
   * @param {string} companyURL
   * @returns {boolean}
   */
  validateCompanyURL = (companyURL) => {
    if (!!!companyURL) {
      return true;
    } else {
      // eslint-disable-next-line no-useless-escape
      const isValid = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(companyURL);
      return isValid;
    }
  };

  /**
   * handles submitting the account form
   * 
   * @function
   */
  submitHandler = () => {
    const {
      firstName,
      lastName,
      address,
      postalCode,
      state,
      city,
      country,
      company,
      website,
      companySize,
    } = this.state;

    let userInfoDto = {
      firstName: firstName,
      lastName: lastName,
      address: address,
      postal_Code: postalCode,
      state: state,
      city: city,
      country: country,
      company: company,
      website: website,
      companySize: companySize,
    };
    let isValid = true;

    if (!this.validateCompanySize(companySize)) {
      isValid = false;
      this.setState({
        isValidCompanySize: false,
      });
    }
    if (!this.validateCountry(country)) {
      isValid = false;
      this.setState({
        isValidCountry: false,
      });
    }
    if (!this.validateCompanyURL(website)) {
      isValid = false;
      this.setState({
        isValidWebsite: false,
      });
    }
    if (isValid) {
      // if (!getUserInfo.pending) {
      //   AuthActions.userInfo({ userInfoDto });
      // }
      this.props.AuthActions.userInfo({ userInfoDto, isAccountPage: true });
    }
  };

  render() {
    const {
      readonlyInput,
      firstName,
      lastName,
      company,
      email,
      website,
      companySize,
      address,
      postalCode,
      state,
      city,
      country,
      isValidCompanySize,
      isValidCountry,
      isValidWebsite,
    } = this.state;

    return (
      <div className="account-area">
        <div className="row mt-4">
          <div className="col-md-12 col-lg-8 col-xl-7 col-xxl-6 px-4 pb-lg-4 extra-pading">
            <div className="row">
              <div className="col-md-6 mt-4">
                <label>
                  <TranslateMessage translateKey="first_name" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={firstName}
                  readOnly={readonlyInput !== 0}
                  onChange={(e) =>
                    this.inputChangeHandler(
                      this.capitalizeFirstLetter(e.target.value),
                      "firstName"
                    )
                  }
                  onDoubleClick={(e) => this.makeEditable(e.target.value, 0)}
                  onBlur={(e) => this.makeEditable(e.target.value, -1)}
                />
              </div>
              <div className="col-md-6  mt-4">
                <label>
                  <TranslateMessage translateKey="last_name" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={lastName}
                  readOnly={readonlyInput !== 1}
                  onChange={(e) =>
                    this.inputChangeHandler(
                      this.capitalizeFirstLetter(e.target.value),
                      "lastName"
                    )
                  }
                  onDoubleClick={(e) => this.makeEditable(e.target.value, 1)}
                  onBlur={(e) => this.makeEditable(e.target.value, -1)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-4">
                <label>
                  <TranslateMessage translateKey="email" />*
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-4">
                <label>
                  <TranslateMessage translateKey="address" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  readOnly={readonlyInput !== 3}
                  onChange={(e) =>
                    this.inputChangeHandler(e.target.value, "address")
                  }
                  onDoubleClick={(e) => this.makeEditable(e.target.value, 3)}
                  onBlur={(e) => this.makeEditable(e.target.value, -1)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-4">
                <label>
                  <TranslateMessage translateKey="postal_code" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={postalCode}
                  readOnly={readonlyInput !== 4}
                  onChange={(e) =>
                    this.inputChangeHandler(e.target.value, "postalCode")
                  }
                  onDoubleClick={(e) => this.makeEditable(e.target.value, 4)}
                  onBlur={(e) => this.makeEditable(e.target.value, -1)}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label>
                  <TranslateMessage translateKey="state" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={state}
                  readOnly={readonlyInput !== 5}
                  onChange={(e) =>
                    this.inputChangeHandler(
                      this.capitalizeFirstLetter(e.target.value),
                      "state"
                    )
                  }
                  onDoubleClick={(e) => this.makeEditable(e.target.value, 5)}
                  onBlur={(e) => this.makeEditable(e.target.value, -1)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-4">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  value={city}
                  readOnly={readonlyInput !== 6}
                  onChange={(e) =>
                    this.inputChangeHandler(
                      this.capitalizeFirstLetter(e.target.value),
                      "city"
                    )
                  }
                  onDoubleClick={(e) => this.makeEditable(e.target.value, 6)}
                  onBlur={(e) => this.makeEditable(e.target.value, -1)}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label>
                  <TranslateMessage translateKey="country" />
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={country}
                  onChange={(e) =>
                    this.inputChangeHandler(e.target.value, "country")
                  }
                >
                  <option selected disabled></option>
                  {countries.map((counrty) => {
                    return <option value={counrty.name}>{counrty.name}</option>;
                  })}
                </select>
                {!isValidCountry && (
                  <div className="error-msg">
                    {<TranslateMessage translateKey="countryRequired" />}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-1 remove-col">
            <div className="border-div"></div>
          </div>
          <div className="col-md-12 col-lg-3 px-4">
            <div className="row">
              <div className="col-md-12 mt-4">
                <label>
                  <TranslateMessage translateKey="Company Size" />
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={companySize}
                  onChange={(e) =>
                    this.inputChangeHandler(e.target.value, "companySize")
                  }
                >
                  <option selected disabled></option>
                  {companySizes.map((companySize) => {
                    return (
                      <option value={companySize.code}>
                        {companySize.text}
                      </option>
                    );
                  })}
                </select>
                {!isValidCompanySize && (
                  <div className="error-msg">
                    {<TranslateMessage translateKey="companySizeRequired" />}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-4">
                <label>
                  <TranslateMessage translateKey="company_name" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={company}
                  readOnly={readonlyInput !== 8}
                  onChange={(e) =>
                    this.inputChangeHandler(e.target.value, "company")
                  }
                  onDoubleClick={(e) => this.makeEditable(e.target.value, 8)}
                  onBlur={(e) => this.makeEditable(e.target.value, -1)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-4">
                <label>
                  <TranslateMessage translateKey="website_URL" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={website}
                  readOnly={readonlyInput !== 9}
                  onChange={(e) =>
                    this.inputChangeHandler(e.target.value, "website")
                  }
                  onDoubleClick={(e) => this.makeEditable(e.target.value, 9)}
                  onBlur={(e) => this.makeEditable(e.target.value, -1)}
                />
                {!isValidWebsite && (
                  <div className="error-msg">
                    {<TranslateMessage translateKey="invalid_website_url" />}
                  </div>
                )}
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12 mt-4">
                <label>
                  <TranslateMessage translateKey="tax_n_vat" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={tax_vat}
                  readOnly={readonlyInput !== 10}
                  onChange={(e) =>
                    this.inputChangeHandler(e.target.value, "tax_vat")
                  }
                  onDoubleClick={(e) => this.makeEditable(e.target.value, 10)}
                  onBlur={(e) => this.makeEditable(e.target.value, -1)}
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 col-lg-6 px-4 text-end">
            <Button
              className="blue-btn mt-2 mb-3"
              variant="contained"
              onClick={this.submitHandler}
            >
              <TranslateMessage translateKey="save_changes" />

              {/* {!this.props.signUp.pending ? (
                "Sign up"
              ) : (
                <CircularProgress className="loading-btn-circle" />
              )} */}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * mapping the redux state to props
 * @param {*} state 
 * @returns props
 */
function mapStateToProps(state) {
  return {
    predict: state.Predict,
    detections: state.Predict.detections,
    appMode: state.Predict.appMode,
    selectedDropdownElement: state.Predict.selectedDropdownElement,
    pageImage: state.Predict.pageImage,
    viewMode: state.Predict.viewMode,
    getUserInfo: state.Auth.getUserInfo,
    updateUser: state.Auth.updateUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AuthActions: bindActionCreators(AuthActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Account)
);
