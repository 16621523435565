export const productTourConfig = [
  {
    selector: '[product-tour="canvas"]',
    content: `Your uploaded documents are shown here. Colored frames represent found Texts or Tables.`
  },
  {
    selector: '[product-tour="edit_btn"]',
    content: `LEFT: Edit mode switch. Click to edit, delete or draw new frames;
    MIDDLE: all tools;
    RIGHT: page count.`
  },
  {
    selector: '[product-tour="text_section"]',
    content: `Found Text Entries: Click in all fields to manually edit. Select the data by clicking the checkbox, Auto-Selection will be done thereafter.
    Click on the Headers info Icons for more context.`
  },
  {
    selector: '[product-tour="table_section"]',
    content: `Found Table Entries: Same as Text Entries. Move the line between Text & Table sections to maximize the space.`
  },
  {
    selector: '[product-tour="table_header"]',
    content: `LEFT: '1st row is a Header' Icon which defines the Table Header; 'Transpose' icon flips the table. RIGHT: click through all the tables of this page.`
  },
  {
    selector: '[product-tour="next_btn"]',
    content: `Click through the pages & see the Preview of your results.`
  },
  {
    selector: '[product-tour="help"]',
    content: `Help: Product Tour Start, Tutorial Video, FAQs & support email.`
  },
];