import React from 'react';
import SideBar from '../components/shared/sideBar';
import ValidateSideBar from '../modules/validate/ValidateSideBar';

// The main layout of the drawing section
const ValidateLayout = ViewComponent => {



  return class extends React.Component {

    render() {
      return (
        <>
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="left-side px-0 h-100">
                <SideBar />
              </div>
              <div className="right-side px-0 h-100">
                <div className="row m-0">
                  <div className="col-6 p-0">
                    <ViewComponent />
                  </div>
                  <div className="col-6 p-0"><ValidateSideBar /></div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
};

export default ValidateLayout;
