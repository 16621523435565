import { createLogic } from "redux-logic";
import endPoints from "../../../util/EndPoint";
import actions from "./actions";
import predictActions from "../../validate/ducks/actions";
import types from "./types";
import API from "../../../util/HTTPClient";
import history from "../../../util/History";
import Alerts from "../../shared/Alerts";
import { SUBSCRIPTION } from "../../../util/Constants";

const signIn = createLogic({
  type: types.SIGN_IN,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running signIn Service");
    HTTPClient.Post(endPoints.LOGIN, action.payload.signInDto)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.signInSuccess(data));
        dispatch(actions.signUpSpinnerCompleted())
        dispatch(predictActions.updateNumberOfPages(data));
        if (!data?.data?.page_available) {
          dispatch(predictActions.changeValidateStatus({
            isValidatePage: false,
            isSelectItem: false,
          }))
        }
        const userData = {
          data: {
            user: {
              ...data.data.user,
            }
          },
          numberOfPages: data.data.number_of_pages,
          pageAvailable: data.data.page_available,
        }
        localStorage.setItem("user", JSON.stringify(userData));
        dispatch(actions.setSession(userData));
        const disableNavigation = action.payload.signInDto?.disableNavigation;
        if (disableNavigation === undefined || disableNavigation === null)
          history.push("/upload");
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.message || "Something went wrong!";

        dispatch(
          actions.signInFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const signUp = createLogic({
  type: types.SIGN_UP,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running SIGN_UP Service");
    HTTPClient.Post(endPoints.SIGN_UP, action.payload.signUpDto)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.signUpSuccess(data));
        dispatch(actions.signIn({ signInDto: { ...action.payload.signUpDto, disableNavigation: true } }));
        history.push("/pricing-plans");
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.message || "Something went wrong!";
        dispatch(
          actions.signUpFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const signOut = createLogic({
  type: types.SIGN_OUT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running SIGN_OUT Service");
    HTTPClient.Get(endPoints.SIGN_OUT)
      .then((resp) => resp.data)
      .then((data) => {
        localStorage.removeItem("id");
        localStorage.removeItem("user");
        dispatch(actions.signOutSuccess(data));
        dispatch(predictActions.clearPredictReducer());

        history.push("/sign-in");
        window.location.reload(true);
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.signOutFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getPricingData = createLogic({
  type: types.GET_PRICING_DATA,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.PRICING_PLANS)
      .then((resp) => resp.data.data)
      .then((data) => {
        dispatch(actions.getPricingDataSuccess(data.plans));
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        dispatch(
          actions.getPricingDataFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const subscribeFreePlan = createLogic({
  type: types.SUBSCRIBE_FREE_PLAN,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    dispatch(actions.pendingActionStarted(types.SUBSCRIBE_FREE_PLAN))
    HTTPClient.Post(endPoints.SUBSCRIBE_FREE_PLAN, {})
      .then((resp) => resp.data.data)
      .then((data) => {
        dispatch(actions.subscribeFreePlanSuccess(data));
        dispatch(actions.confirmSubscription())
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        dispatch(
          actions.subscribeFreePlanFail({
            title: "Error!",
            message: errorMessage,
          })
        );
        Alerts.showError("You are not eligible for free plan. Please select a paid plan.");
        dispatch(actions.pendingActionCompleted(types.SUBSCRIBE_FREE_PLAN))
      })
      .then(() => done());
  },
});

const getUserPortalPaymentInfo = createLogic({
  type: types.GET_USER_PORTAL_PAYMENT_INFO,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    dispatch(actions.pendingActionStarted(types.GET_USER_PORTAL_PAYMENT_INFO))
    const url = `${endPoints.USER_PORTAL_SESSION}?returnUrl=${window.location.origin}/account`;
    HTTPClient.Get(url, {})
      .then((resp) => resp.data.data)
      .then((data) => {
        dispatch(actions.pendingActionCompleted(types.GET_USER_PORTAL_PAYMENT_INFO))
        window.open(data.checkoutSessionUrl, "_self");
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        dispatch(
          actions.subscribeFreePlanFail({
            title: "Error!",
            message: errorMessage,
          })
        );
        Alerts.showError("Something went wrong. Please try again later.");
        dispatch(actions.pendingActionCompleted(types.SUBSCRIBE_FREE_PLAN))
      })
      .then(() => done());
  },
});



const subscribePricingPlan = createLogic({
  type: types.SUBSCRIBE_PRICING_PLAN,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    dispatch(actions.pendingActionStarted(types.SUBSCRIBE_PRICING_PLAN))
    const payload = {
      successUrl: window.location.origin + SUBSCRIPTION.SUCCESS_URL,
      cancelUrl: window.location.origin + SUBSCRIPTION.CANCEL_URL,
      planIntervalId: action.payload.planIntervalId,
    };
    HTTPClient.Post(endPoints.SUBSCRIBE_PLAN, payload)
      .then((resp) => resp.data.data)
      .then((data) => {
        dispatch(actions.subscribePricingPlanSuccess(data));
      })
      .catch((err) => {
        dispatch(actions.pendingActionCompleted(types.SUBSCRIBE_PRICING_PLAN))
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        dispatch(
          actions.subscribePricingPlanFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const confirmSubscription = createLogic({
  type: types.CONFIRM_SUBSCRIPTION,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    dispatch(actions.pendingActionStarted(types.CONFIRM_SUBSCRIPTION))

    console.log("Running GET_USER_INFO Service");
    HTTPClient.Get(endPoints.USER_INFO)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getUserInfoSuccess(data.data));
        const userFromLocalStorage = localStorage.getItem("user");
        if (userFromLocalStorage) {
          let user = JSON.parse(userFromLocalStorage);
          user.data.user.subscription = data.data.user.subscription;
          localStorage.setItem("user", JSON.stringify(user));
        }
        history.push("/upload");
        dispatch(actions.confirmSubscriptionSuccess(data))
        dispatch(actions.pendingActionCompleted(types.SUBSCRIBE_FREE_PLAN))
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        dispatch(
          actions.getUserInfoFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());

  },
});


const subscribePricingPlanSuccess = createLogic({
  type: types.SUBSCRIBE_PRICING_PLAN_SUCCESS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    const payload = action.payload;
    window.open(payload.checkoutSessionUrl, "_self");
    dispatch(actions.pendingActionCompleted(types.SUBSCRIBE_PRICING_PLAN))
  },
});

const userInfo = createLogic({
  type: types.USER_INFO,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running USER_INFO Service");
    HTTPClient.Post(endPoints.USER_INFO, action.payload.userInfoDto)
      .then((resp) => resp.data)
      .then((data) => {
        let user = {
          data: null,
          user: data,
        };
        localStorage.setItem("id", data?._id);
        localStorage.setItem("user", JSON.stringify(user));
        Alerts.showSuccess("Account details updated");
        dispatch(actions.userInfoSuccess(data));
        // if (!action.payload.isAccountPage) {
        //   history.push("/upload");
        // }
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.userInfoFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const updateUserInfo = createLogic({
  type: types.UPDATE_USER_INFO,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running UPDATE_USER_INFO Service");
    HTTPClient.Put(endPoints.USER_INFO, action.payload.userInfoDto)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.updateUserInfo());
        const sessionData = localStorage.getItem("user");

        if (sessionData) {
          let userData = JSON.parse(sessionData);
          userData.data.user.product_tour_viewed = true;
          let updatedUserData = JSON.stringify(userData);
          localStorage.setItem('user', updatedUserData);
        }
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        Alerts.showError(errorMessage);
      })
      .then(() => done());
  },
});

const changePassword = createLogic({
  type: types.CHANGE_PASSWORD,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running CHANGE_PASSWORD Service");
    HTTPClient.Put(endPoints.CHANGE_PASSWORD, action.payload.updatePasswordDto)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.changePasswordSuccess(data));
        action.payload.callback();
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.changePasswordFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const resetPassword = createLogic({
  type: types.RESET_PASSWORD,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running RESET_PASSWORD Service");
    HTTPClient.Post(endPoints.RESET_PASSWORD, action.payload.resetPasswordDto)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.resetPasswordSuccess(data));
        action.payload.callback();
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.resetPasswordFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getUserInfo = createLogic({
  type: types.GET_USER_INFO,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running GET_USER_INFO Service");
    HTTPClient.Get(endPoints.USER_INFO)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getUserInfoSuccess(data.data));
        const userFromLocalStorage = localStorage.getItem("user");
        if (userFromLocalStorage) {
          let user = JSON.parse(userFromLocalStorage);
          user.data.user.subscription = data.data.user.subscription;
          localStorage.setItem("user", JSON.stringify(user));
        }
        dispatch(actions.confirmSubscriptionSuccess(data))
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        dispatch(
          actions.getUserInfoFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const deleteUser = createLogic({
  type: types.DELETE_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running DELETE_USER Service");
    HTTPClient.Delete(endPoints.USER_ACCOUNT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.deleteUserSuccess(data));
        history.push("/");
        window.location.reload(true);
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.deleteUserFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const updateUser = createLogic({
  type: types.UPDATE_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running update user Service");
    HTTPClient.Put(endPoints.UPDATE_USER, action.payload.updateUserDto)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.updateUserSuccess(data));
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.error || "Something went wrong!";
        dispatch(
          actions.updateUserFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const checkEmail = createLogic({
  type: types.CHECK_EMAIL,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }

    HTTPClient.Get(endPoints.CHECK_EMAIL + `/${action.payload.email}`)
      .then((resp) => resp.data)
      .then((data) => {
        console.log("check email response :" + data);
        dispatch(actions.checkEmailSuccess(data));

        history.push("/verify-email?email=" + action.payload.email);
        window.location.reload(true);
      })
      .catch((err) => {
        var errorMessage =
          err?.response?.data?.message || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.checkEmailFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});


let services = [
  signIn,
  signUp,
  signOut,
  userInfo,
  changePassword,
  getUserInfo,
  deleteUser,
  updateUser,
  checkEmail,
  resetPassword,
  updateUserInfo,
  getPricingData,
  subscribePricingPlan,
  subscribePricingPlanSuccess,
  subscribeFreePlan,
  confirmSubscription,
  getUserPortalPaymentInfo
];

export default services;
