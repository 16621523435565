import message from "../locales/en/translation.json";

const lang = {
  message: {
    ...message,
  },
  locale: "en",
};

export default lang;
