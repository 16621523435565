import React from 'react'
import Faq from "react-faq-component";

const data = {
    title: "Frequently Asked Questions",
    rows: [
        {
            title: "What is one Page?",
            content: `One Page is a PDF A4 Page.`,
        },
        {
            title: "What is OCR, AI Table & key-value-pair, GPT Recognition?",
            content:
                "OCR is a technology used for scanned documents. Using AI docutize.ai identifies Tables and key-value-pairs (E.g. 'Name: John', 'Name' is the key, and 'John' is the value.). Using GPT Large Language Models we can identify specific values from Texts (e.g. all telephone numbers in Texts)",
        },
        {
            title: "Does docutize.ai support handwriting?",
            content: `Handwriting is not included at this point for Free & Pro Plans. Enterprise Plans can include any kind of automatic identification up to your needs.`,
        },
        {
            title: "Do you offer a free plan?",
            content: `Yes! The Free plan includes 20 PDF pages per month. You can test all features, including data export.`,
        },
        {
            title: "What happens to unused pages?",
            content: `Unused pages expire at the end of each billing period, and your account is topped up with new pages.`,
        },
        {
            title: "Do you offer discounts?",
            content: `Yes. All our plans come with a ~ 30-35% discount when you subscribe annually (equivalent to 3,5-4 months free).`,
        },
        {
            title: "What happens once I have used all my pages?",
            content: `You won't be able to parse data from PDF documents until the next billing period. Alternatively, you can upgrade your subscription.`,
        },
        {
            title: "How does Self-Setup work?",
            content: `docutize.ai is a No-Code Platform designed to be used by everybody. You create a Selection Template without support. Just try it, you will be guided through by the platform.`,
        },
        {
            title: "What kind of Support is offered?",
            content: `The Free Plan does not include any support. Pro Plan have Email Support included with a 48 hour guaranteed response rate. Enterprise have Premium Support included that are up to your needs.`,
        },
        {
            title: "Can I change my subscription?",
            content: `Our flexible subscription plans allow you to upgrade, downgrade or cancel your subscription at any time. Upgrades and downgrades take effect immediately. Cancellation is also possible at any time.`,
        },
        {
            title: "What payment types do you accept?",
            content: `We accept all major credit cards, including MasterCard, Visa, American Express, Diners, and JCB. We do not accept cryptocurrency, PayPal, or bank transfers as payment methods.`,
        },
        {
            title: "How do I know it's safe to link my bank card?",
            content: `docutize.ai never stores your bank card information. Your payment card details are encrypted and safely stored by the payment processor Stripe.`,
        },
        {
            title: "Can I get a refund?",
            content: `We typically provide very limited refunds because you can try out all the features using our Free plan before you upgrade to a paid membership. However, if you are unsatisfied with our services, please contact us.`,
        },
        {
            title: "Can I get an invoice?",
            content: `Yes, you will get the invoice automatically via email and can view and download it anytime in the Customer Portal at Account > My Plan > Change my Plan > Billing.`,
        },
        {
            title: "Do you offer high-volume plans?",
            content: `Yes! Contact us to discuss our high-volume pricing.`,
        },
    ],
};
const styles = {
    // bgColor: 'white',
    titleTextSize: '28px',
    titleTextColor: 'black',
    rowTitleTextSize: 'medium',
    rowContentTextSize: 'medium',
    rowTitleColor: 'black',
    rowContentColor: 'grey',
};

const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
};

export const FAQ = () => {
    return (
        <Faq
            data={data}
            styles={styles}
            config={config}
        />
    )
}
