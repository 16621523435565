import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import TranslateMessage from "../../components/translateManager/TranslateMessage";
/**
 * 
 * Renders the Verify Email page
 * @component
 *
 * @returns {JSX.Element} The JSX element representing the VerifyEmail component.
 */
class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null
    };
  }

  componentDidMount(){  }

  componentWillUnmount(){  }

  render() {
    return (
      <div className="d-flex flex-column justify-content-center h-100">
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
            <div className="login-container">
              <div className="row text-center mb-5">
                <h2 className="logo-name mb-0">
                <a href="https://www.docutize.ai">docutize.ai</a>
                  {/* <Link to="/" underline="none">
                  docutize.ai
                  </Link> */}
                </h2>
              </div>
              <div className="row first-row">
                <div className="col-md-12 col-lg-12">
                  <h3 className="text-lg-start">
                    <TranslateMessage translateKey="verify_mail" />
                  </h3>
                </div>
              </div>
              <div className="row second-row">
                <div className="col-12 mt-4">
                 <p>An email has been sent to your email.</p>
                 <br/>
                 <p>Please Verify!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(VerifyEmail);
