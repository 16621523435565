import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppMode } from '../../util/Constants';
import ToggleButton from './ToggleButton';
import TranslateMessage from '../translateManager/TranslateMessage';

// contains below functionalities
// ** change to column or defauld mode
// ** Drawing mode selector
// ** Enable drowing mode button
// ** Enable drag mode button
function ElementSelector({
  handleChange,
  deleteHandler,
  option,
  modeHandler,
  active,
  changeViewMode,
  isColumnView: isEditView,
  submitColumnChange,
  canDelete,
  isDisableConfirm,
  columnArray: updatedTableArr,
  setAnchorEl,
  editViewModalHandler,
}) {
  return (
    <div className="">
      <ButtonGroup className="element-selector" style={{ paddingLeft: '10px', paddingTop: '8px' }}>
        <ToggleButton changeViewMode={changeViewMode} isEditView={isEditView} editViewModalHandler={editViewModalHandler}/>

        {isEditView && (
          <Button
            disabled={isDisableConfirm}
            style={{
              backgroundColor: isDisableConfirm ? '#fdfdfd' : 'whitesmoke',
              lineHeight: 'unset',
            }}
            variant={isDisableConfirm ? 'text' : 'contained'}
            className={isDisableConfirm ? 'first-btn disabled-bdr' : 'first-btn-blue'}
            onClick={() => submitColumnChange()}
          >
            <span className={isDisableConfirm ? 'disabled-clr' : ''}>
              <TranslateMessage translateKey="confirm_table" />
            </span>
          </Button>
        )}

        {isEditView && (
          <Button
            style={{
              backgroundColor: active === AppMode.DRAWING_ACTION ? '#E0E0E0' : 'whitesmoke',
            }}
            className="middle-btn"
            onClick={e => {
              setAnchorEl?.(e);
            }}
          >
            <EditIcon />
          </Button>
        )}

        {isEditView && (
          <Button
            style={{
              backgroundColor: !canDelete ? '#fdfdfd' : 'whitesmoke',
            }}
            disabled={!canDelete}
            className={!canDelete ? 'middle-btn disabled-bdr' : 'middle-btn'}
            onClick={() => deleteHandler()}
          >
            <DeleteIcon className={!canDelete ? 'disabled-clr' : ''} />
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
}

export default ElementSelector;
