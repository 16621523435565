import { useRef } from 'react';
import { Line } from 'react-konva';
import { ElmOperation } from 'util/Constants';

/**
 * Shape Component.
 *
 * @param {string} id - The unique identifier of the shape.
 * @param {Array} startX - An array containing the x-coordinates of the start point of the shape.
 * @param {Array} startY - An array containing the y-coordinates of the start point of the shape.
 * @param {Function} onChange - The change event handler for the shape.
 * @param {Function} onClickHandler - The click event handler for the shape.
 * @param {boolean} isEditMode - A boolean indicating whether the component is in edit mode.
 * @param {Function} setSelected - A function to set the selected state of the shape.
 * @param {Object} trRef - A reference to a transformer component.
 * @param {Object} limitX - An object containing x-coordinate limits for dragging the shape.
 * @param {Object} canvasSize - The size of the canvas.
 * @param {Function} setOperation - A function to set the operation on the shape.
 * @param {Object} line - An object representing the line of the shape.
 * @param {Object} rest - Additional props for the component.
 *
 * @description
 * The `Shape` component represents a draggable and editable shape on the canvas. It displays a line with specified
 * start and end points. It supports various interaction behaviors such as dragging, clicking, and scaling.
 */
const Shape = ({
  id,
  table,
  selectElementHandler,
  startX: lineStart,
  startY: lineEnd,
  onChange: onChangeHandler,
  onClickHandler = undefined,
  isEditMode,
  setSelected,
  trRef,
  limitX,
  canvasSize,
  setOperation,
  line,
  ...rest
}) => {
  const ref = useRef();

  // console.log(">>>", line)

  const startX = lineStart[0];
  const startY = lineStart[1];
  const endX = lineEnd[0];
  const endY = lineEnd[1];

  /**
   * dragBoundFunc Function.
   *
   * @function
   * @param {Object} pos - The current position object containing x and y coordinates.
   * @returns {Object} - Returns the updated position object with bounded x and y coordinates.
   *
   * @description
   * The `dragBoundFunc` function is used to constrain the movement of a draggable element within
   * specified limits along the x-axis while allowing unrestricted movement along the y-axis. It calculates
   * and adjusts the x-coordinate based on the provided limits and the current position, ensuring that
   * the element remains within the defined range.
   */
  const dragBoundFunc = function (pos) {
    const y = this.absolutePosition().y;
    let x = pos.x;

    if (startX + pos.x < limitX.xStart || startX + pos.x > limitX.xEnd) {
      x = this.absolutePosition().x;
    }

    return {
      x,
      y,
    };
  };

/**
 * Updates the selected table with the provided information and triggers the
 * selectElementHandler with the table object and its ID.
 *
 * @function
 * @name updateSelectedTable
 * @returns {void}
 */
  const updateSelectedTable = () => {
    const tableObject = {
      boundingPoly: table?.boundingPoly,
      id: table?.id,
      type: table?.id
    }

    // Trigger the selectElementHandler with the table object and its ID
    selectElementHandler(tableObject, tableObject.id);
  }

  /**
   * onClick Event Handler.
   *
   * @function
   * @description
   * The `onClick` function is an event handler triggered when the associated element is clicked. It is responsible
   * for performing actions such as selecting the clicked element, preventing event propagation (bubbling),
   * stopping the dragging behavior of the element within a Konva stage, and invoking a custom click handler (`onClickHandler`)
   * if provided. The function ensures that the clicked element is selected and its drag behavior is halted, and
   * it may also call an additional click handler function, passing the event and the clicked element.
   *
   * @param {Event} e The click event object.
   */
  const onClick = e => {
    const node = ref.current;
    setSelected(node);

    updateSelectedTable();

    node.cancelBubble = true;
    node.getStage().stopDrag();
    onClickHandler?.(e, node);
  };

  /**
   * Handles drag start event handler.
   * 
   * @function
   * @description Set selected as null when drag start.
   */
  const handleDragStart = () => {
    setSelected(null);
    updateSelectedTable();
  };

  /**
   * handleDragEnd Event Handler.
   * 
   * @function
   * @param {Event} e The drag end event object.
   * 
   * @description
   * The `handleDragEnd` function is an event handler triggered when the dragging of an element is completed.
   * It is responsible for updating the selected element, setting the operation to indicate an update (using `setOperation`),
   * and finalizing any changes made during the drag operation.
   *
   * @param {Konva.Node} node - The Konva Node that was being dragged. 
   */
  const handleDragEnd = e => {
    const node = ref.current;
    setSelected(node);
    setOperation(ElmOperation.UPDATED, node.attrs);
  };

  return (
    <>
      <Line
        id={id}
        ref={ref}
        points={[startX, startY, endX, endY]}
        stroke="#808080"
        strokeWidth={7.5}
        fill="#808080"
        draggable={isEditMode}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        dragBoundFunc={dragBoundFunc}
        onClick={onClick}
        onTransformEnd={e => {
          const node = ref.current;
          node.scaleX(1);
          node.scaleY(1);
        }}
        {...rest}
      />
      {/* <DoubleArrowIcon startX={startX} startY={startY} endX={endX} endY={endY} /> */}
    </>
  );
};

export default Shape;
