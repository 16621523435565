
import React, { useEffect } from 'react'
import Modal from '@mui/material/Modal';
import { Button, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PredictActions } from "../../../modules/validate/ducks";
import CircularProgress from '@mui/material/CircularProgress';
import "./GPTRetryModal.css";
import { connect, useDispatch } from 'react-redux';

interface GPTRetryModalDialogProps {
    open: boolean,
    handleSkip: () => void,
    gptRetryStatus: {
        loading: boolean,
        pending: boolean,
        data: boolean,
    }
}

const GPTRetryModalDialogX = ({ handleSkip, open, gptRetryStatus }: GPTRetryModalDialogProps) => {

    const dispatch = useDispatch();
    const [isBtnLoading, setIsBtnLoading] = React.useState(false);

    useEffect(() => {
        if (gptRetryStatus.loading) {
            setIsBtnLoading(true)
        } else {
            setIsBtnLoading(false)
        }
    }, [gptRetryStatus])

    const handleRetry = () => {
        dispatch(PredictActions.retryGptGeneration({
            callBack: closeModal
        }))
    }

    const closeModal = () => {     
        handleSkip();
    }

    const handleSkipX = () => {
        
        handleSkip();
        dispatch(PredictActions.skipGptRetry())
    }

    return (
        <>
            <Modal
                disableEscapeKeyDown={true}
                open={open}
            >
                <div className='gpt-retry-modal'>
                    <div className='modal-header border-0 pb-0'>
                        <div></div>
                        <div>
                            <Tooltip title={<span style={{ fontSize: 15 }}>{"We rely on third parties to provide GPT service. As their servers are currently not available, we can not provide GPT. Your Upload is saved for 24 hours to retry GPT. You can use the service without GPT now. For Tables & key-value-pair (e.g. Name: John) extraction the service will work nonetheless. For extracting in-text values please come back later."}</span>}>
                                <InfoOutlinedIcon sx={{ color: 'black', fontSize: "26px" }}></InfoOutlinedIcon>
                            </Tooltip>
                        </div>
                    </div>
                    <div className='modal-body'>
                        <div className='title-container'>
                            <div className='title'>GPT is currently not available</div>
                        </div>

                        <div className='description'>
                            Please come back later (within 24 hours) to this page and click ‘Retry’. Click ‘Skip GPT’ to use docutize.ai without GPT now.
                        </div>

                        <div className='btns-container'>
                            <div style={{ width: 200 }}></div>

                            <Button disabled={isBtnLoading} onClick={handleRetry} className={`start-btn`}>
                                Retry
                                {isBtnLoading && <CircularProgress size={16} style={{ color: "inherit", marginLeft: 10 }} />}
                            </Button>

                            <Button disabled={isBtnLoading} onClick={handleSkipX} className={`skip-btn`}>
                                Skip GPT
                            </Button>

                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}


function mapStateToProps(state) {
    return {
        gptRetryStatus: state.Predict.gptRetryStatus
    };
}

export const GPTRetryModalDialog = connect(mapStateToProps, null)(GPTRetryModalDialogX);