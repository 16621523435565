import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { AuthActions } from '../../modules/auth/ducks';
import countries from '../../data/Country';
import TranslateMessage from '../translateManager/TranslateMessage';
import companySizes from '../../data/CompanySizes';
import ReCAPTCHA from 'react-google-recaptcha';

class AboutYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      address: null,
      postalCode: null,
      state: null,
      city: null,
      country: '',
      company: null,
      website: null,
      companySize: '',
      isValidFName: true,
      isValidLName: true,
      isValidCompanySize: true,
      isValidCountry: true,
      isValidWebsite: true,
      isSubmitEnabled: false,
    };
  }

  /**
   * Change Input change handler
   * @param {string} input user input
   * @param {string} field field name
   */
  inputChangeHandler = (input, field) => {
    if (field === 'companySize') {
      if (this.validateCompanySize(input)) {
        this.setState({
          [field]: input,
          isValidCompanySize: true,
        });
      } else {
        this.setState({
          [field]: input,
          isValidCompanySize: false,
        });
      }
    } else if (field === 'country') {
      if (this.validateCountry(input)) {
        this.setState({
          [field]: input,
          isValidCountry: true,
        });
      } else {
        this.setState({
          [field]: input,
          isValidCountry: false,
        });
      }
    } else if (field === 'website') {
      if (this.validateCompanyURL(input)) {
        this.setState({
          [field]: input,
          isValidWebsite: true,
        });
      } else {
        this.setState({
          [field]: input,
          isValidWebsite: false,
        });
      }
    } else {
      this.setState({
        [field]: input,
      });
    }
  };

  /**
   * Validate First Name
   * @param {string} fName
   * @returns {boolean} true/false
   */
  // validateFName = fName => {
  //   return fName?.length !== 0;
  // };

  /**
   * Validate Last Name
   * @param {string} lName user input last name
   * @returns {boolean}
   */
  // validateLName = lName => {
  //   return lName?.length !== 0;
  // };

  /**
   * Validate Company size
   * @param {string} companySize
   * @returns {boolean}
   */
  validateCompanySize = companySize => {
    return companySize?.length !== 0;
  };

  /**
   * Validate Country
   * @param {string} country
   * @returns {boolean}
   */
  validateCountry = country => {
    return country?.length !== 0;
  };

  /**
   * Validate company URL
   * @param {string} companyURL
   * @returns {boolean}
   */
  validateCompanyURL = companyURL => {
    if (!!!companyURL) {
      return true;
    } else {
      const isValid =
      // eslint-disable-next-line no-useless-escape
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
          companyURL,
        );
      return isValid;
    }
  };

  /**
   * Submit handler
   */
  submitHandler = () => {
    const {
      firstName,
      lastName,
      address,
      postalCode,
      state,
      city,
      country,
      company,
      website,
      companySize,
    } = this.state;
    const { getUserInfo, AuthActions } = this.props;
    /**
     * !! TODO - PAYLOAD NEED TO REFACTOR
     */
    let userInfoDto = {
      firstName: firstName,
      lastName: lastName,
      address: address,
      postalCode: postalCode,
      state: state,
      city: city,
      country: country,
      company: company,
      website: website,
      companySize: companySize,
    };
    let isValid = true;

    // if (!this.validateCompanySize(companySize)) {
    //   isValid = false;
    //   this.setState({
    //     isValidCompanySize: false,
    //   });
    // }
    // if (!this.validateCountry(country)) {
    //   isValid = false;
    //   this.setState({
    //     isValidCountry: false,
    //   });
    // }
    if (!this.validateCompanyURL(website)) {
      isValid = false;
      this.setState({
        isValidWebsite: false,
      });
    }
    if (isValid) {
      if (!getUserInfo.pending) {
        AuthActions.userInfo({ userInfoDto });
      }
    }
  };

  handleKeyPress = (e) => {
      if (e.key === "Enter") {
        this.submitHandler();
      }
  }

  handleOnChange = t => {
    if (t) {
      this.setState({ isSubmitEnabled: true });
    }
  };

  onExpiredHandle = () => {
    this.setState({ isSubmitEnabled: false });
  };

  render() {
    const { userInfo } = this.props;
    const {
      isValidCompanySize,
      isValidCountry,
      isValidWebsite,
      isSubmitEnabled,
    } = this.state;
    return (
      <div className="d-flex flex-column justify-content-center h-100">
        <div className="row justify-content-center">
          <div className="col-12 about-you-container">
            <div className="row">
              <div className="col-lg-1 remove-col"></div>
              <div className="col-md-12 col-lg-6 px-4 pb-lg-4">
                <h3 className="text-lg-start">
                  <TranslateMessage translateKey="tell_us_about_you" />
                </h3>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-1 remove-col"></div>
              <div className="col-md-12 col-lg-6 px-4 pb-lg-4">
                <div className="row">
                  <div className="col-md-6 mt-4">
                    <label>
                      <TranslateMessage translateKey="first_name" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => this.inputChangeHandler(e.target.value, 'firstName')}
                      onKeyDown={(e) => this.handleKeyPress(e)}
                    />
                  </div>

                  <div className="col-md-6  mt-4">
                    <label>
                      <TranslateMessage translateKey="last_name" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => this.inputChangeHandler(e.target.value, 'lastName')}
                      onKeyDown={(e) => this.handleKeyPress(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <label>
                      {' '}
                      <TranslateMessage translateKey="address" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => this.inputChangeHandler(e.target.value, 'address')}
                      onKeyDown={(e) => this.handleKeyPress(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-4">
                    <label>
                      {' '}
                      <TranslateMessage translateKey="postal_code" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => this.inputChangeHandler(e.target.value, 'postalCode')}
                      onKeyDown={(e) => this.handleKeyPress(e)}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <label>
                      <TranslateMessage translateKey="state" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => this.inputChangeHandler(e.target.value, 'state')}
                      onKeyDown={(e) => this.handleKeyPress(e)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mt-4">
                    <label>
                      <TranslateMessage translateKey="city" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => this.inputChangeHandler(e.target.value, 'city')}
                      onKeyDown={(e) => this.handleKeyPress(e)}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <label>
                      <TranslateMessage translateKey="country" />*
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={e => this.inputChangeHandler(e.target.value, 'country')}
                    >
                      <option selected disabled></option>
                      {countries.map(counrty => {
                        return <option value={counrty.name}>{counrty.name}</option>;
                      })}
                    </select>
                    {!isValidCountry && (
                      <div className="error-msg">
                        {<TranslateMessage translateKey="countryRequired" />}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-1 remove-col">
                <div className="border-div"></div>
              </div>
              <div className="col-md-12 col-lg-3 px-4">
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <label>
                      <TranslateMessage translateKey="Company Size" />*
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={e => this.inputChangeHandler(e.target.value, 'companySize')}
                      required
                    >
                      <option selected disabled></option>
                      {companySizes.map(companySize => {
                        return <option value={companySize.code}>{companySize.text}</option>;
                      })}
                    </select>
                    {!isValidCompanySize && (
                      <div className="error-msg">
                        {<TranslateMessage translateKey="companySizeRequired" />}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <label>
                      <TranslateMessage translateKey="company_name" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => this.inputChangeHandler(e.target.value, 'company')}
                      onKeyDown={(e) => this.handleKeyPress(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <label>
                      <TranslateMessage translateKey="website_URL" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e => this.inputChangeHandler(e.target.value, 'website')}
                      onKeyDown={(e) => this.handleKeyPress(e)}
                    />
                    {!isValidWebsite && (
                      <div className="error-msg">
                        {<TranslateMessage translateKey="invalid_website_url" />}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-1 remove-col"></div>
              <div className="col-md-12 col-lg-7 px-4 text-end">
                <div className="recaptcha-box">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                    onChange={this.handleOnChange}
                    onExpired={this.onExpiredHandle}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 col-lg-7 px-4 text-end">
                <Button
                  className="blue-btn mt-2 mb-3"
                  variant="contained"
                  onClick={this.submitHandler}
                  disabled={!isSubmitEnabled}
                >
                  {!userInfo.pending ? (
                    'Proceed'
                  ) : (
                    <CircularProgress className="loading-btn-circle" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.Auth.userInfo,
    getUserInfo: state.Auth.getUserInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AuthActions: bindActionCreators(AuthActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AboutYou));
