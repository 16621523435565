import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import UpdatePassword from '../auth/UpdatePassword';
import DeleteAccount from './DeleteAccount';
import Account from './Account';
import { useTranslation } from 'react-i18next';
import MyPlan from './MyPlan';

/**
 *
 *
 * @component
 * @example
 * <TabPanel value={value} index={0}>
 *
 * @param {*} props
 * @returns tab panel containing the child tab
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  /**
   * children - node
   *
   */
  children: PropTypes.node,

  /**
   * index - number
   *
   */
  index: PropTypes.number.isRequired,

  /**
   * value - number
   *
   */
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
 * Account Tab View
 *
 * @returns {JSX.Element} The JSX element representing the Account Tabs View component.
 */
function AccountTabsView() {
  const { t } = useTranslation();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="row">
      <div className="col-12 p-4 tab-area">
        <Box sx={{ width: '100%' }}>
          <Box className="extra-pading-2">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="tabs"
            >
              <Tab
                disableRipple={true}
                label={t('account')}
                icon={<AccountCircleOutlinedIcon />}
                iconPosition="start"
                {...a11yProps(0)}
              />
              {/* Tempary Removed */}
              {/* <Tab
                          disableRipple={true}
                          label="Mail"
                          icon={<EmailOutlinedIcon />}
                          iconPosition="start"
                          {...a11yProps(1)}
                        /> */}
              <Tab
                disableRipple={true}
                label={t('My Plan')}
                icon={<img src="assets/myPlan.svg" width={24}  height={24} alt="my-plan" />}
                iconPosition="start"
                {...a11yProps(1)}
              />
              <Tab
                disableRipple={true}
                label={t('password')}
                icon={<LockOutlinedIcon />}
                iconPosition="start"
                {...a11yProps(2)}
              />
              <Tab
                disableRipple={true}
                label={t('delete_account')}
                icon={<NoAccountsOutlinedIcon />}
                iconPosition="start"
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Account />
          </TabPanel>
          {/* Tempary Removed */}
          {/* <TabPanel value={value} index={1}>
                      <Mail />
                    </TabPanel> */}
          <TabPanel value={value} index={1}>
            <MyPlan />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UpdatePassword />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <DeleteAccount />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}

export default AccountTabsView;
