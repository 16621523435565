import { Button } from "@mui/material";
import React, { Component } from "react";
import CorrectionUpload from "../../components/correctionUpload/CorrectionUpload";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PredictActions } from "../validate/ducks";
import EndPoint from "../../util/EndPoint";
import Loader from "../../components/shared/Loader";
import { DEFAULT_SIMILARITY_VALUE } from "../../util/Constants";
import TranslateMessage from "../../components/translateManager/TranslateMessage";

class WordCorrector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      dictionaries: [],
      similarityValue: DEFAULT_SIMILARITY_VALUE,
    };
  }

  uploadDocument = (file) => {
    const { documents } = this.state;
    documents.push(file);
    this.setState({
      documents: documents,
    });
  };

  uploadDictionary = (file) => {
    const { dictionaries } = this.state;
    dictionaries.push(file);
    this.setState({
      dictionaries: dictionaries,
    });
  };

  handleStart = () => {
    const { dictionaries, documents, similarityValue } = this.state;
    let formData = new FormData();
    dictionaries.forEach((dic) => {
      formData.append("dic", dic);
    });
    documents.forEach((doc) => {
      formData.append("doc", doc);
    });

    formData.append("similarityValue", similarityValue);

    this.props.predictActions.uploadCorrections({
      formData: formData,
      callBack: this.callbackHandler,
    });
  };

  callbackHandler = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  downloadHandler = () => {
    window.location.assign(EndPoint.DOWNLOAD_DICTIONARY_EXAMPLE);
  };

  similarityValueChangeHandler = (e) => {
    const similarityValue = e.target.value;
    if (similarityValue >= 100) {
      this.setState({ similarityValue: 100 });
    } else {
      this.setState({ similarityValue });
    }
  };

  render() {
    const { dictionaries, documents, similarityValue } = this.state;
    const { uploadCorrections } = this.props;

    return (
      <div className="d-flex flex-column justify-content-center h-100">
        {uploadCorrections.pending && <Loader />}
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-9 col-lg-8 col-xl-8 col-xxl-8">
            <div className="upload-container">
              <h5 className="result-heading" style={{ marginBottom: "14px" }}>
                <TranslateMessage translateKey="step_01" />
              </h5>
              <CorrectionUpload
                title="documents"
                acceptFileTypes="txt, csv and xls"
                accept={
                  "text/csv,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
                multiple={true}
                files={documents}
                callBack={this.uploadDocument}
              ></CorrectionUpload>
              <h6 className="result-heading text-center mt-1">
                <b>
                  <TranslateMessage translateKey="uploaded_files" />: &nbsp;
                  {documents.length}
                </b>
              </h6>

              <div className="d-flex align-content-center justify-content-between mt-4">
                <h5 className="result-heading" style={{ marginBottom: "14px" }}>
                  <TranslateMessage translateKey="step_02" />
                </h5>
                <h6 className="result-heading mb-3 mt-1">
                  <TranslateMessage translateKey="download_sample_dictionary_file" />

                  <a
                    href="/#"
                    style={{
                      color: "#0029ff",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={() => this.downloadHandler()}
                  >
                    <TranslateMessage translateKey="here" />
                  </a>
                </h6>
              </div>

              <CorrectionUpload
                title="dictionary"
                acceptFileTypes="xls"
                accept={
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
                multiple={true}
                files={dictionaries}
                callBack={this.uploadDictionary}
              ></CorrectionUpload>
              <h6 className="result-heading text-center mt-1">
                <b>
                  <TranslateMessage translateKey="uploaded_files" />: &nbsp;
                  {dictionaries.length}
                </b>
              </h6>
              <div className="similarity">
                <div className="row" style={{ marginTop: "40px" }}>
                  <div className="col-4">
                    <div className="d-flex justify-content-end align-items-center h-100">
                      <h5 className="result-heading mb-0">
                        <TranslateMessage translateKey="similarity_value" />{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="d-flex justify-content-center">
                      <input
                        onChange={this.similarityValueChangeHandler}
                        type="number"
                        className="form-control"
                        style={{ width: "150px" }}
                        min={0}
                        max={100}
                        value={similarityValue}
                        defaultValue={DEFAULT_SIMILARITY_VALUE}
                      />
                    </div>
                  </div>
                  <div className="col-4"></div>
                </div>
              </div>
              <div className="btn-login text-center mt-3">
                <Button
                  onClick={() => this.handleStart()}
                  className="blue-btn mt-3 mb-3"
                  variant="contained"
                >
                  <TranslateMessage translateKey="start" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    uploadCorrections: state.Predict.uploadCorrections,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    predictActions: bindActionCreators(PredictActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WordCorrector)
);
