// //seller-dashboard views
import Upload from './upload/Upload';
import Download from './upload/Download/index';
import PageNotFound from './shared/PageNotFound';
import ProgressBar from '../components/upload/progressIndicator';
import Landing from './shared/Landing';
import Account from './account/Account';
import DeleteAccount from './account/DeleteAccount';
import WordCorrector from './wordCorrector/WordCorrector';
import SignIn from './auth/SignIn';
import SignUp from './auth/SignUp';
import UpdatePassword from './auth/UpdatePassword';
import Validate from './validate/Validate';
import AboutYou from '../components/shared/AboutYou';
import AccountTabsView from './account/AccountTabsView';
import PrivacyPolicy from './shared/PrivacyPolicy';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import VerifyEmail from './auth/VerifyEmail';
import { PricingPage } from './pricing/PricingPage';

var views = {
  //seller-dashboard
  ValidateView: Validate,
  ResultView: Download,
  UploadView: Upload,
  ProgressView: ProgressBar,
  PageNotFoundView: PageNotFound,
  SignInView: SignIn,
  SignUpView: SignUp,
  AboutYouView: AboutYou,
  LandingView: Landing,
  AccountView: Account,
  UpdatePasswordView: UpdatePassword,
  DeleteAccountView: DeleteAccount,
  WordCorrectorView: WordCorrector,
  PrivacyPolicyView: PrivacyPolicy,
  AccountTabsView: AccountTabsView,
  ForgotPasswordView: ForgotPassword,
  ResetPasswordView: ResetPassword,
  VerifyEmailView: VerifyEmail,
  PricingView: PricingPage,
};

export default views;
