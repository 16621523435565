import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { Rating } from "@mui/material";
import { useDispatch } from "react-redux";
import { PredictActions } from "../../modules/validate/ducks";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { HANDLING, OVERALL_EXP, RESULT } from "../../util/Constants";
import { BootstrapDialogTitle } from "./BootstrapDialogTitle";
import TranslateMessage from "../translateManager/TranslateMessage";

const TIME_PER_PAGE = 300;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function FeedbackModal(props) {
  const [overall_exp, setOverallExp] = React.useState(0);
  const [results, setResults] = React.useState(0);
  const [handling, setHandling] = React.useState(0);
  const [comments, setComments] = React.useState("");

  const dispatch = useDispatch();

  /**
   * Send Feedback handler
   */
  const sendFeedbackHandler = () => {
    var reqBody = {
      overall_exp: overall_exp,
      results: results,
      handling: handling,
      comments: comments,
    };

    dispatch(PredictActions.addFeedback({ feedback: reqBody }));
    setOverallExp(0);
    setResults(0);
    setHandling(0);
    setComments("");
    props.closeModal();
  };

  const addRatingsHandler = (type, value) => {
    if (type === RESULT) setResults(value);
    else if (type === OVERALL_EXP) setOverallExp(value);
    else if (type === HANDLING) setHandling(value);
  };
  
  return (
    <div>
      <BootstrapDialog
        onClose={props.closeModal}
        aria-labelledby="customized-dialog-title"
        open={props.showModal}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.closeModal}
        >
          <h5 className="result-heading mb-0 text-center">
            <TranslateMessage translateKey="congratulations" />
          </h5>
        </BootstrapDialogTitle>
        <DialogContent>
          <div className="px-4 py-3">
            <Typography>
              <TranslateMessage translateKey="you_have_successfully_processed" />
              <b>
                {props?.fileCount + " "}
                <TranslateMessage translateKey={props?.fileCount === 1 ? "file" : "files"} />
              </b>
              <TranslateMessage translateKey="and" />
              <b>
                {props?.numOfPages + " "}
                <TranslateMessage translateKey={props?.numOfPages === 1 ? "page" : "pages"} />
              </b>
              .
              <TranslateMessage translateKey="process_suuccess_message1" />{" "}
              <b>{Math.round((props.numOfPages * TIME_PER_PAGE) / 60)} </b>
              <TranslateMessage translateKey="process_suuccess_message2" />
            </Typography>
            <br></br>
            <Typography>
              <TranslateMessage translateKey="feedback_title" />
              <b>
                <TranslateMessage translateKey="feedback" />
              </b>
              .
            </Typography>
            <br></br>
            <table className="ratigg-tbl" style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>
                    <div className="">
                      <TranslateMessage translateKey="overall_experience" />
                    </div>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <Rating
                      name="size-large"
                      value={overall_exp}
                      onChange={(event, value) =>
                        addRatingsHandler(OVERALL_EXP, value)
                      }
                      size="large"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="">
                      <TranslateMessage translateKey="results" />
                    </div>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <Rating
                      name="size-large"
                      value={results}
                      onChange={(event, value) =>
                        addRatingsHandler(RESULT, value)
                      }
                      size="large"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="">
                      <TranslateMessage translateKey="handling" />
                    </div>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <Rating
                      name="size-large"
                      value={handling}
                      onChange={(event, value) =>
                        addRatingsHandler(HANDLING, value)
                      }
                      size="large"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <Typography>
              <TranslateMessage translateKey="anything_else_you_want_to_add" />
            </Typography>
            <textarea
              className="form-control mt-1"
              id="exampleFormControlTextarea1"
              rows="3"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            ></textarea>
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center pt-0 pb-4">
          <Button
            className="blue-btn"
            variant="contained"
            autoFocus
            onClick={sendFeedbackHandler}
            disabled={!overall_exp && !results && !handling && !comments}
          >
            <TranslateMessage translateKey="send" />
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    addRating: state.Predict.addRating,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    predictActions: bindActionCreators(PredictActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedbackModal)
);
