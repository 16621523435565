import axios from "axios";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

// Create axios instance for api calls
var instance = null;

export const setAuth = () => {
  instance = axios.create({
    baseURL: "",
    timeout: 120000,

    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        error.response &&
        error.response.status !== undefined &&
        error.response.status === 401
      ) {
        localStorage.clear();
        window.location = "/sign-in";
      }
      return Promise.reject(error);
    }
  );
};

const HTTPClient = {
  Get: async (route, data) => {
    instance || (await setAuth());
    return instance.get(route, JSON.stringify(data));
  },
  Post: async (route, data) => {
    instance || (await setAuth());
    return instance.post(route, JSON.stringify(data));
  },
  Put: async (route, data) => {
    instance || (await setAuth());
    return instance.put(route, JSON.stringify(data));
  },
  Delete: async (route, data) => {
    instance || (await setAuth());
    return instance.delete(route, { data: data });
  },
  Patch: async (route, data) => {
    instance || (await setAuth());
    return instance.patch(route, JSON.stringify(data));
  },
  Download: async (route, data) => {
    return axios
      .create({
        baseURL: "",
        timeout: 30000,
      })
      .get(route, JSON.stringify(data));
  },
  Upload: async (route, data) => {
    return axios
      .create({
        baseURL: "",
        timeout: 300000,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .post(route, data);
  },
};

export default HTTPClient;
