import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { CheckedCheckBoxIcon, EmptyCheckBoxIcon } from '../shared/CustomComponents';
import {
  ORDERING_VALUE_NULL,
  ORDERING_VALUE_NONE,
  ElementType,
  UNUSED,
} from '../../util/Constants';
import TranslateMessage from '../translateManager/TranslateMessage';
import ToolTip from "../shared/ToolTip";

type KeyValuePairProps = {
  keyVal: string;
  val: string;
  data: any;
  selectedElement: any;
  updateKeyValueHandler: (data: Object) => void;
  getTableHeadings: any;
  selectedKeyValuesChange: (key: string, data: Object) => void;
  selectedKeyValues: any;
  color: string;
  addOrderingValue: any;
  isKeySelected: boolean;
  pending: boolean;
  deSelectPending: boolean;
};

const valStyle = { color: 'black', fontSize: '13px' };
const inputStyle: React.CSSProperties = {
  width: '100%',
  border: '1px solid #ced4da',
  borderRadius: '3px',
};


// Displays the key value pairs as a list in the right side bar
export const KeyValuePair = (props: KeyValuePairProps) => {
  const {
    data,
    selectedElement,
    updateKeyValueHandler,
    getTableHeadings,
    selectedKeyValuesChange,
    selectedKeyValues,
    addOrderingValue,
    isKeySelected,
    pending,
    deSelectPending
  } = props;

  const [key, setKey] = useState<string>(props.keyVal);
  const [isKeyEdit, setKeyEdit] = useState(false);
  const [val, setVal] = useState(props.val);
  const [isValEdit, setValEdit] = useState(false);
  const [valRows, setValRows] = useState(1);
  const [keyRows, setKeyRows] = useState(1);
  const [keyIsNone, setKeyIsNone] = useState(false);

  const keyVal = useRef<HTMLTextAreaElement | null>(null);
  const valVal = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setKey(data.key);
    setVal(data.value);
  }, [data]);

  useEffect(() => {
    const rows = valVal.current!.scrollHeight / 18
    setValRows(rows);
  }, [val])

  useEffect(() => {
    const rows = keyVal.current!.scrollHeight / 18
    setKeyRows(rows);
  }, [key])

  useEffect(() => {
    if (key !== ORDERING_VALUE_NONE) {
      setKeyIsNone(false);
    }
  }, [key]);

  let myRef = useRef(data.id);


  const updateKey = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      setKeyEdit(false);
      setKey(keyVal.current!.value);
      updateKeyValueHandler({
        data: data,
        updatedData: keyVal.current!.value,
        isKey: true,
      });
    }
  };

  const updateVal = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      setValEdit(false);
      setVal(valVal.current!.value);
      updateKeyValueHandler({
        data: data,
        updatedData: valVal.current!.value,
        isKey: false,
      });
    }
  };

  const updateKeyFocusOut = () => {
    setKeyEdit(false);
    if (key !== keyVal.current!.value) {
      setKey(keyVal.current!.value);
      updateKeyValueHandler({
        data: data,
        updatedData: keyVal.current!.value,
        isKey: true,
      });
    }
  };

  /**
   * Check box handler
   *
   * @function
   * @description Set the state according to check box state.
   */
  const checkboxHandler = () => {
    if (!isKeySelected) {
      if (key === ORDERING_VALUE_NONE) {
        setKeyIsNone(true);
        return;
      }

      let dto = addOrderingValue(key, data);
      selectedKeyValuesChange(dto?.heading, data);
      //console.log("Ordering value DTO :", dto);
    } else {
      selectedKeyValuesChange(ORDERING_VALUE_NULL, data);
    }
  };

  const updateValFocusOut = () => {
    setValEdit(false);
    if (val !== valVal.current!.value) {
      setVal(valVal.current!.value);
      updateKeyValueHandler({
        data: data,
        updatedData: valVal.current!.value,
        isKey: false,
      });
    }
  };

  const renderKeyEditView = () => {
    return (
      <div className="pe-3">
        <textarea
          style={{ ...valStyle, ...inputStyle }}
          defaultValue={key}
          ref={keyVal}
          rows={keyRows}
          onKeyDown={e => {
            updateKey(e);
          }}
          onBlur={updateKeyFocusOut}
        />
        {adjustTrigger()}
      </div>
    );
  };

  const adjustTrigger = () => {
    if (isValEdit || isKeyEdit) {
      setTimeout(() => {
        if (isValEdit) {
          valVal.current?.focus();
        } else if (isKeyEdit) {
          keyVal.current?.focus();
        }
      }, 100);
    }
    return null;
  };

  const renderValEditView = () => {
    return (
      <div>
        <textarea
          // className="txt-val-input"
          style={{ ...valStyle, ...inputStyle }}
          rows={valRows}
          defaultValue={val}
          ref={valVal}
          onKeyDown={e => {
            updateVal(e);
          }}
          onBlur={updateValFocusOut}
        />
        {adjustTrigger()}
      </div>
    );
  };

  // const selectedElementID = selectedElement.data.id;
  const groupIndex = selectedElement.data.group_index;

  const executeScroll = data => {
    if (data.scroll === true && typeof myRef.current !== 'string') {
      myRef.current.scrollIntoView();
    }

    return '#E0E0E0';
  };

  const style_one: React.CSSProperties = useMemo(() => {
    return {
      paddingTop: '12px',
      paddingBottom: '12px',
      background: data.group_index === groupIndex ? executeScroll(data) : undefined,
      borderBottom: '1px solid #E0E0E0',
    };
  }, [groupIndex, data]);

  const style_two = useMemo(() => {
    return {
      backgroundColor: `${props.color}`,
    };
  }, [props.color]);

  return (
    <div style={style_one} ref={myRef} onChange={executeScroll}>
      <Grid container style={{ alignItems: 'center' }}>
        <Grid xs={1} md={1} lg={1}>
          <div className="chk-grid" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="color-box" style={style_two}></div>
            <div>
              <ToolTip title="Select the Box to extract the Value" imgUrl={null} isBtn={true}>
                {(pending && data?.showLoading) || data?.deselected ? (
                  <div className="loading-square-container">
                    <div className="spinner" />
                  </div>) : (
                  <Checkbox
                    disabled={pending || deSelectPending}
                    onChange={checkboxHandler}
                    checked={isKeySelected}
                    icon={<EmptyCheckBoxIcon />}
                    checkedIcon={<CheckedCheckBoxIcon />}
                    sx={{
                      '&.Mui-disabled': {
                        opacity: pending || deSelectPending ? 0.4 : 1,
                      },
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                  />
                )}
              </ToolTip>
            </div>
          </div>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          {data.type === ElementType.ELM_KEY_VAL ? (
            <>
              <select
                name="keyValuePair"
                id="keyValuePair"
                className={`form-select form-select-sm ${isKeySelected ? 'dropdown-select' : 'dropdown-un-select'
                  }'}`}
                value={
                  selectedKeyValues?.find(value => value.id === data.id) !== undefined
                    ? selectedKeyValues?.find(value => value.id === data.id).selected_title
                    : null
                }
                onChange={e => selectedKeyValuesChange(e.target.value, data)}
              >
                <option value={UNUSED}>{UNUSED}</option>
                {getTableHeadings.data.map(heading => {
                  return <option value={heading}>{heading}</option>;
                })}
              </select>
            </>
          ) : null}
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          {renderKeyEditView()}
          {keyIsNone && (
            <div className="error-msg-text-selection mt-1">
              {<TranslateMessage translateKey="replace_none_with_a_value" />}
            </div>
          )}
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          {renderValEditView()}
        </Grid>
      </Grid>
    </div>
  );
};
