import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEn from "../../lngProvider/locales/en/translation.json";
import translationsEnTest from "../../lngProvider/locales/test/translation.json";
import { DEFAULT_LANGUAGE_CODE } from "../../lngProvider";
// .use(Backend)
// .use(LanguageDetector)

i18next
  // .use(HttpApi)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // supportedLngs: ["en", "ar", "fr"],
    fallbackLng: DEFAULT_LANGUAGE_CODE,
    debug: true,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    // react: { useSuspense: false },
    // backend: {
    //   loadPath: '/assets/locales/{{lng}}/translation.json',
    // },
    resources: {
      eng: { translation: translationsEn },
      deu: { translation: translationsEnTest },
    },
  });

export default i18next;
