import React, { forwardRef } from 'react';
import { Circle, Group, Line } from 'react-konva';

/**
 * Custom component that renders an add icon as two lines
 * AddIcon Component.
 *
 * @component
 * @param {Object} props - The props object containing component properties.
 * @param {number} props.x - The x-coordinate of the icon's center.
 * @param {number} props.y - The y-coordinate of the icon's center.
 * @param {number} props.size - The size of the icon.
 * @param {function} props.onClick - The click event handler for the icon.
 *
 * @description
 * The `AddIcon` component renders an icon with horizontal and vertical lines, resembling an addition sign.
 * It receives props such as the x and y coordinates, size, and an optional click event handler (`onClick`).
 * 
 */
const AddIcon = forwardRef((props, ref) => {
  // get the x, y and size props
  const { x, y, size, onClick } = props;

  // calculate the points for the horizontal and vertical lines
  const horizontalPoints = [x - size / 2, y, x + size / 2, y];
  const verticalPoints = [x, y - size / 2, x, y + size / 2];

  return (
    <Group ref={ref} onClick={onClick}>
      <Circle x={x} y={y} radius={size * 0.6} fill="#808080" />
      <Line points={horizontalPoints} stroke="white" strokeWidth={2} />
      <Line points={verticalPoints} stroke="white" strokeWidth={2} />
    </Group>
  );
});

export default AddIcon;
