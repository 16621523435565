import React from 'react';
import "./styles/ValidateSideBar.css";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import '../../App.css';

const ToolTip = ({ title, imgUrl, isBtn, children }) => {
    return (
        <>
            {isBtn ?
                <Tooltip title={<span style={{ fontSize: 15 }}>{title}</span>}>
                    {children}
                </Tooltip>
                :
                <Tooltip title={<span style={{ fontSize: 15 }}>{title}</span>}>
                    {imgUrl ? (
                        <img src={imgUrl} height="18px" alt="add row" />
                    ) : (
                        <span className="info-icon"><InfoOutlinedIcon style={{ width: "14px", height: "14px" }} /></span>
                    )}
                </Tooltip>
            }
        </>

    );
}

export default ToolTip;