import React from "react";
import CheckIcon from "@mui/icons-material/Check";

export const EmptyCheckBoxIcon = () => {
  return <div className="check_ico"></div>;
};

export const CheckedCheckBoxIcon = () => {
  return (
    <div className="check_ico_checked">
      <CheckIcon fontSize="inherit" />
    </div>
  );
};
