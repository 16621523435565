import React from "react";
import SideBar from "../components/shared/sideBar";
import "../components/shared/styles/predict.css";

const PageNotFoundLayout = (ViewComponent) => {
  return class extends React.Component {
    componentDidMount() {}
    render() {
      return (
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="left-side px-0 h-100">
              <SideBar />
            </div>
            <div className="right-side h-100 bdr-leftt">
              <ViewComponent />
            </div>
          </div>
        </div>
      );
    }
  };
};

export default PageNotFoundLayout;
