const action_header = "auth/";

const types = {
  SIGN_IN: action_header + "SIGN_IN",
  SIGN_IN_SUCCESS: action_header + "SIGN_IN_SUCCESS",
  SIGN_IN_FAILED: action_header + "SIGN_IN_FAILED",

  SIGN_UP: action_header + "SIGN_UP",
  SIGN_UP_SUCCESS: action_header + "SIGN_UP_SUCCESS",
  SIGN_UP_FAILED: action_header + "SIGN_UP_FAILED",
  SIGN_UP_SPINNER_COMPLETED: action_header + "SIGN_UP_SPINNER_COMPLETED",

  DISPLAY_PRICING_PAGE: action_header + "DISPLAY_PRICING",

  GET_PRICING_DATA: action_header + "GET_PRICING_DATA",
  GET_PRICING_DATA_SUCCESS: action_header + "GET_PRICING_DATA_SUCCESS",
  GET_PRICING_DATA_FAILED: action_header + "GET_PRICING_DATA_FAILED",

  SUBSCRIBE_PRICING_PLAN: action_header + "SUBSCRIBE_FOR_PRICING",
  SUBSCRIBE_PRICING_PLAN_SUCCESS: action_header + "SUBSCRIBE_FOR_PRICING_SUCCESS",
  SUBSCRIBE_PRICING_PLAN_FAILED: action_header + "SUBSCRIBE_FOR_PRICING_FAILED",

  CONFIRM_SUBSCRIPTION: action_header + "CONFIRM_SUBSCRIPTION",
  CONFIRM_SUBSCRIPTION_SUCCESS: action_header + "CONFIRM_SUBSCRIPTION_SUCCESS",
  CONFIRM_SUBSCRIPTION_FAILED: action_header + "CONFIRM_SUBSCRIPTION_FAILED",

  SUBSCRIBE_FREE_PLAN: action_header + "SUBSCRIBE_FOR_FREE",
  SUBSCRIBE_FREE_PLAN_SUCCESS: action_header + "SUBSCRIBE_FOR_FREE_SUCCESS",
  SUBSCRIBE_FREE_PLAN_FAILED: action_header + "SUBSCRIBE_FOR_FREE_FAILED",

  PENDING_ACTION_STARTED: action_header + "PENDING_ACTION_STARTED",
  PENDING_ACTION_COMPLETED: action_header + "PENDING_ACTION_COMPLETED",

  GET_USER_PORTAL_PAYMENT_INFO: action_header + "GET_USER_PORTAL_PAYMENT_INFO",
  GET_USER_PORTAL_PAYMENT_INFO_SUCCESS: action_header + "GET_USER_PORTAL_PAYMENT_INFO_SUCCESS",
  GET_USER_PORTAL_PAYMENT_INFO_FAILED: action_header + "GET_USER_PORTAL_PAYMENT_INFO_FAILED",

  SIGN_OUT: action_header + "SIGN_OUT",
  SIGN_OUT_SUCCESS: action_header + "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILED: action_header + "SIGN_OUT_FAILED",

  USER_INFO: action_header + "USER_INFO",
  USER_INFO_SUCCESS: action_header + "USER_INFO_SUCCESS",
  USER_INFO_FAILED: action_header + "USER_INFO_FAILED",

  CHANGE_PASSWORD: action_header + "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: action_header + "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILED: action_header + "CHANGE_PASSWORD_FAILED",

  GET_USER_INFO: action_header + "GET_USER_INFO",
  GET_USER_INFO_SUCCESS: action_header + "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILED: action_header + "GET_USER_INFO_FAILED",

  DELETE_USER: action_header + "DELETE_USER",
  DELETE_USER_SUCCESS: action_header + "DELETE_USER_SUCCESS",
  DELETE_USER_FAILED: action_header + "DELETE_USER_FAILED",

  UPDATE_USER: action_header + "UPDATE_USER",
  UPDATE_USER_SUCCESS: action_header + "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILED: action_header + "UPDATE_USER_FAILED",

  CHECK_EMAIL: action_header + "CHECK_EMAIL",
  CHECK_EMAIL_SUCCESS: action_header + "CHECK_EMAIL_SUCCESS",
  CHECK_EMAIL_FAILED: action_header + "CHECK_EMAIL_FAILED",

  RESET_PASSWORD: action_header + "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: action_header + "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILED: action_header + "RESET_PASSWORD_FAILED",

  SET_SESSION: action_header + "SET_SESSION",

  UPDATE_USER_INFO: action_header + "UPDATE_USER_INFO",
};

export default types;
