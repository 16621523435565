import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "@mui/material/Button";
import SampleImg from "../../assets/images/logo.png";
import { IS_ENABLE_SIGN_UP, SIGN_UP_URL } from "../../util/Constants";
import TranslateMessage from "../../components/translateManager/TranslateMessage";

/**
 * 
 * Landing Page
 * @component
 *
 * @returns {JSX.Element} The JSX element representing the Landing component.
 *
 */
class Landing extends Component {
  render() {
    return (
      <div className="d-flex flex-column justify-content-center h-100">
        <div className="lading-container">
          <img
            src={SampleImg}
            className="landing-logo my-2"
            alt="logo"
            loading="lazy"
          />
          <h2 className="logo-name my-2">docutize.ai</h2>
          <div className="mt-5">
            <Button
              className="blue-btn"
              variant="contained"
              disabled={!IS_ENABLE_SIGN_UP}
              onClick={() =>
                this.props.history.push(
                  IS_ENABLE_SIGN_UP ? "/sign-up" : SIGN_UP_URL
                )
              }
            >
              <TranslateMessage translateKey="singup" />
            </Button>
          </div>
          <div className="mt-3 mb-5">
            <Button
              className="blue-btn"
              variant="contained"
              onClick={() => this.props.history.push("/sign-in")}
            >
              <TranslateMessage translateKey="login" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Landing);
