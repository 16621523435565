import React from "react";
import { useTranslation } from "react-i18next";

const DownloadCard = ({
  section,
  type,
  description,
  source,
  image,
  className,
  selectHandler,
  isMulti,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${className} ${source.includes(type) && `selected`} `}
      onClick={() => selectHandler(section, type)}
    >
      {isMulti ? (
        <div>
          <div className="d-flex justify-content-center">
            <img className="me-1" src={image} alt="" />
            <img className="ms-1" src={image} alt="" />
          </div>
        </div>
      ) : (
        <img className="me-1" src={image} alt="" />
      )}
      <small className="mt-2">{t(description)}</small>
    </div>
  );
};

export default DownloadCard;
