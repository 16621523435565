import { Button } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import './PricingCard.css'

export interface PricingFeature {
    title: string
    available: boolean
    attributes?: {
        type: 'radio' | 'checkbox'
        radioGroupId?: string
        price: number
        active: boolean
        onClick?: () => void
    }
}

export interface PricingCardProps {
    monthlyPrice: number
    yearlyPrice: number
    title: string
    description: string
    features: PricingFeature[]
    buttonText: string
    onClick: () => void
    hideSymbol?: boolean
    customBillingPeriodText?: string
    customPrice?: string
    hideBillingPeriod?: boolean
    symbol?: string
    mode?: 'monthly' | 'yearly'
    icon?: React.ReactNode,
    btnClassName?: string
    background?: React.CSSProperties['background']
    isBtnLoading?: boolean
}

export const PricingCard: React.FC<PricingCardProps> = ({
    mode = "monthly",
    hideBillingPeriod = false,
    symbol = "$",
    hideSymbol = false,
    title,
    buttonText,
    description,
    monthlyPrice,
    features,
    customPrice,
    customBillingPeriodText,
    background,
    btnClassName,
    yearlyPrice,
    onClick,
    isBtnLoading = false
}) => {


    const [basePrice, setBasePrice] = React.useState(mode === 'monthly' ? monthlyPrice : yearlyPrice);

    const [selectedFeature, setSelectedFeature] = React.useState(
        features.find((feature) => feature.attributes?.type === 'radio')?.title || ''
    );

    // default selected attributes
    React.useEffect(() => {
        setSelectedFeature(
            features.find((feature) => feature.attributes?.type === 'radio')?.title || ''
        );
    }, [features])

    React.useEffect(() => {
        handleRadioChange(selectedFeature)
    }, [selectedFeature])

    const uniqueRadioIds = features.filter(feature => feature.attributes?.type === 'radio')
        .map(feature => feature.attributes?.radioGroupId)
        .filter((value, index, self) => self.indexOf(value) === index)


    const handleRadioChange = (featureTitle: string) => {
        const feature = features.find(feature => feature.title === featureTitle)
        if (feature?.attributes?.onClick) {
            if (mode === 'monthly') {
                setBasePrice(feature.attributes.price)
            }
            if (mode === 'yearly') {
                setBasePrice(feature.attributes.price / 12)
            }
        }
    }

    const formatPrice = (price: number) => {
        // if has decimal
        if (price % 1 !== 0) {
            return price.toFixed(2)
        }
        return price
    }

    const handleSubmit = () => {
        const feature = features.find(feature => feature.title === selectedFeature)
        if (feature?.attributes?.onClick) {
            feature.attributes.onClick()
        } else {
            onClick()
        }
    }

    return (
        <div className='pricing-card' style={{ background }}>
            <div>
                <div className='title-section'>
                    <div className='title'>{title}</div>
                    <div className='price-display'>
                        {!hideSymbol && symbol}{customPrice || formatPrice(basePrice)}
                        {!hideBillingPeriod && <span className='pricing-period'>{!customBillingPeriodText && "/"} {customBillingPeriodText || "month"}</span>}
                    </div>
                    <div className='package-description'>{description}</div>
                </div>
                <div className='body-section'>
                    {uniqueRadioIds.map((id) => {
                        return <RadioGroup
                            key={id}
                            value={selectedFeature}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            onChange={(e: any) => {
                                setSelectedFeature(e.target.value);
                            }}
                        >
                            {
                                features.filter(feature => feature.attributes?.radioGroupId === id)
                                    .map((feature) => {
                                        const featurePrice = `${symbol}${mode === "yearly" ? formatPrice(feature.attributes!.price / 12) : formatPrice(feature.attributes!.price)}` || "";
                                        return <FormControlLabel
                                            key={feature.title}
                                            value={feature.title}
                                            control={<Radio className='mui-radio' icon={<span className='white-circle'></span>} checkedIcon={<span className='white-circle'><img className='check-icon' src="assets/check.svg" alt="" /></span>} />}
                                            label={<span className='feature-text'>{feature.title} - {featurePrice}{'/mo'}</span>} />
                                    })
                            }
                        </RadioGroup>

                    })}
                    {features.map(({ title: feature, available, attributes }) => {
                        return (
                            <div className='features' key={feature}>
                                {attributes ? <>
                                    {attributes?.type === 'checkbox' && (
                                        <div>
                                            <input type="checkbox" />
                                            <div>{feature} - {attributes.price}</div>
                                        </div>
                                    )}</> : <>
                                    <div className='feature'>
                                        <div>
                                            {available ? <span className='transparent-circle'><img className='check-icon' src="assets/check.svg" alt="" /></span> : <CloseIcon />}
                                        </div>
                                        <span className='feature-text'> {feature}</span>
                                    </div>
                                </>}
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className='lower-section'>
                <Button onClick={handleSubmit} className={`subscription-btn ${btnClassName}`}>
                    {buttonText}
                    {isBtnLoading && <CircularProgress size={16} style={{ color: "inherit", marginLeft: 10 }} />}
                </Button>
            </div>
        </div>
    )
}