import { createLogic } from "redux-logic";
import endPoints from "../../../util/EndPoint";
import actions from "./actions";
import types from "./types";
import API from "../../../util/HTTPClient";
import history from "../../../util/History";
import Alerts from "../../shared/Alerts";

const updateKeyValue = createLogic({
  type: types.UPDATE_KEY_VALUE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running updateKeyValue Service");
    HTTPClient.Put(endPoints.KEY_VALUE_PAIR, action.payload.updateKeyValueDto)
      .then((resp) => resp.data)
      .then((data) => {
        Alerts.showSuccess(data?.message || "Text updated");
        dispatch(actions.updateKeyValueSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.updateKeyValueFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const updateTableHeading = createLogic({
  type: types.UPDATE_TABLE_HEADING,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running updateTableHeading Service");
    HTTPClient.Put(
      endPoints.TABLE_HEADING,
      action.payload.updateTableHeadingDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.updateTableHeadingSuccess(data.data));
        data.data.alart_message !== '' && Alerts.showWarning(data.data.alart_message);
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.updateTableHeadingFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const updateSelectedKeyValue = createLogic({
  type: types.UPDATE_SELECTED_KEY_VALUE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running updateSelectedKeyValue Service");
    HTTPClient.Put(
      endPoints.KEY_VALUE_PAIR_ORDERING,
      action.payload.updateSelectedKeyValueDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.updateSelectedKeyValueSuccess(data.data));
        data.data.alart_message !== '' && Alerts.showWarning(data.data.alart_message);
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.updateSelectedKeyValueFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const updateRow = createLogic({
  type: types.UPDATE_ROW,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running UPDATE_ROW Service");
    HTTPClient.Put(endPoints.CELL, action.payload.dataDto)
      .then((resp) => resp.data)
      .then((data) => {
        Alerts.showSuccess(data?.message || "Table updated");
        dispatch(actions.updateRowSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.updateRowFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addKeyValue = createLogic({
  type: types.ADD_KEY_VALUE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running ADD_KEY_VALUE Service");
    HTTPClient.Post(endPoints.KEY_VALUE_PAIR, action.payload.dataDto)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.addKeyValueSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.addKeyValueFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addTable = createLogic({
  type: types.ADD_TABLE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running ADD_TABLE Service");
    HTTPClient.Post(endPoints.ADD_TABLE, action.payload.dataDto)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.addTableSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.addTableFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addColumn = createLogic({
  type: types.ADD_COLUMN,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running ADD_COLUMN Service");
    HTTPClient.Post(endPoints.ADD_COLUMN, action.payload.dataDto)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.addColumnSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.addColumnFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const uploadPDF = createLogic({
  type: types.UPLOAD_PDF,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running UPLOAD_PDF Service");
    HTTPClient.Upload(endPoints.UPLOAD_FILES, action.payload.formData)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(
          actions.getProgressCompleteReporter({
            progress: 0,
            isComplete: false,
          })
        );
        dispatch(actions.uploadPDFSuccess(data));
        // dispatch(actions.startPredict());

        setTimeout(() => {
          action.payload.callBack();
        }, 1200);
      })

      .catch((err) => {
        var errorMessage = err?.response?.data?.message || "Something went wrong!";
        dispatch(
          actions.uploadPDFFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const uploadConfig = createLogic({
  type: types.UPLOAD_CONFIG,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running UPLOAD_CONFIG Service");
    HTTPClient.Upload(endPoints.UPLOAD_CONFIG, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.uploadConfigSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.uploadConfigFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getFileCount = createLogic({
  type: types.GET_FILE_COUNT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running GET_FILE_COUNT Service");
    HTTPClient.Get(endPoints.DOCUMENT_COUNT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getFileCountSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.getFileCountFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getTableHeadings = createLogic({
  type: types.GET_TABLE_HEADINGS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running GET_TABLE_HEADINGS Service");
    HTTPClient.Get(endPoints.DOCUMENT_HEADING_TEMPLATE_ALL)
      .then((resp) => resp.data)
      .then((data) => {
        console.log("🚀 ~ file: service.js ~ line 368 ~ .then ~ data", data);
        dispatch(actions.getTableHeadingsSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.getTableHeadingsFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addHeadingTempplate = createLogic({
  type: types.ADD_HEADING_TEMPLATE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running ADD_HEADING_TEMPLATE Service");
    HTTPClient.Post(
      endPoints.ORDERING_HEADING_TEMPLATE,
      action.payload.headingTemplateDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.addHeadingTemplateSuccess(data.data));
        action.payload.callback(action.payload.headingTemplateDto.heading, action.payload.headingTemplateDto.data);
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.addHeadingTemplateFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addHeadingTemplateForTable = createLogic({
  type: types.ADD_HEADING_TEMPLATE_FOR_TABLE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running ADD_HEADING_TEMPLATE_FOR_TABLE Service");
    HTTPClient.Post(
      endPoints.ORDERING_HEADING_TEMPLATE,
      action.payload.headingTemplateDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.addHeadingTemplateForTableSuccess(data.data));
        action.payload.callback(action.payload.headingTemplateDto.columnHeaderId, action.payload.headingTemplateDto.heading, action.payload.headingTemplateDto.index);
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.addHeadingTemplateForTableFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getPreviewResult = createLogic({
  type: types.GET_PREVIEW_RESULT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    const payload = {
      "fileType": [1],
      "resultType": [1],
      "formating": [2, 3],
      "mergeType": [2],
      "mode": "preview"
    }
    HTTPClient.Post(endPoints.DOCUMENT_GENERATE, payload).then((resp) => {
      dispatch(actions.getPreviewResultSuccess({
        document_data: resp.data.data.document_data,
        order: resp.data.data.order
      }))
    })
  }
});

const skipGptRetry = createLogic({
  type: types.SKIP_GPT_RETRY,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Put(endPoints.SKIP_GPT_RETRY).then((resp) => {
      const data = resp.data;
      dispatch(actions.skipGptRetrySuccess(data.data))
    }).catch((err) => {
      dispatch(actions.skipGptRetryFailed())
    });
  }
});

const retryGptGeneration = createLogic({
  type: types.RETRY_GPT_GENERATION,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.RETRY_GPT_DATA_FETCH).then((resp) => {
      dispatch(actions.retryGptGenerationSuccess(true))
      action.payload.callBack();
      dispatch(actions.getPage({ pageNo: 1 }));
      Alerts.showSuccess("Retry successful! Please wait for the data to load.");
    }).catch((err) => {
      Alerts.showError("Retry failed! Please try again.");
      dispatch(actions.retryGptGenerationFailed(false))
    });
  }
});



const downloadPreviewResult = createLogic({
  type: types.DOWNLOAD_PREVIEW_RESULT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    const payload = {
      "fileType": [1],
      "resultType": [1],
      "formating": [2, 3],
      "mergeType": [2]
    }
    HTTPClient.Post(endPoints.DOCUMENT_GENERATE, payload)
      .then((resp) => {
        action.payload.callback();
        dispatch(actions.downloadPreviewResultSuccess(resp.data))
        console.log(resp)
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.downloadPreviewResultFailed({})
        );
      })
  }
})

const downloadResult = createLogic({
  type: types.DOWNLOAD_RESULT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.DOCUMENT_GENERATE, action.payload.downloadConfig)
      .then((resp) => {
        if (resp.status !== undefined && resp.status === 204) {
          action.payload.showDownloadModalCallback();
          return Promise.reject('No file to download!');
        } else {
          return resp.data;
        }
      })
      .then((data) => {
        dispatch(actions.downloadResultSuccess(data));
        action.payload.callback();
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.downloadResultFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const startPredict = createLogic({
  type: types.START_PREDICT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running START_PREDICT Service");
    HTTPClient.Post(endPoints.START_PREDICT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.startPredictSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.startPredictFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getProgress = createLogic({
  type: types.GET_PROGRESS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running GET_PROGRESS Service");
    HTTPClient.Get(endPoints.GET_PROGRESS)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(
          actions.getProgressSuccess({
            data: data,
            time: Math.round(new Date().getTime() / 1000),
          })
        );
        let progress = Object.assign(data).progress;
        action.payload.callBack(progress);
        if (progress === 100) {
          dispatch(
            actions.getProgressCompleteReporter({
              progress: progress,
              isComplete: progress === 100,
            })
          );
          dispatch(actions.getPage({ pageNo: 1 }));

          dispatch(actions.changeValidateStatus({
            isValidatePage: true,
          }));

          setTimeout(() => {
            history.push("/validate/1");
          }, 2000);

        }
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.getProgressFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const resizeElement = createLogic({
  type: types.RESIZE_ELEMENT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running RESIZE_ELEMENT Service");
    HTTPClient.Post(endPoints.KEY_VALUE_PAIR_RE_PROCESS, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.resizeElementSuccess(data.data));
        window.location.reload(true);
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.resizeElementFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const deleteElement = createLogic({
  type: types.DELETE_ELEMENT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running DELETE_ELEMENT Service", action.payload);
    HTTPClient.Delete(endPoints.KEY_VALUE_PAIR, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(
          actions.deleteElementSuccess({ data: data, payload: action.payload })
        );
        window.location.reload(true);
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.deleteElementFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const deleteTable = createLogic({
  type: types.DELETE_TABLE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running DELETE_TABLE Service", action.payload);
    HTTPClient.Delete(endPoints.TABLE, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.deleteTableSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.deleteTableFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const transformTable = createLogic({
  type: types.TRANSFORM_TABLE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running TRANSFORM_TABLE Service");
    HTTPClient.Post(endPoints.TRANSFORM_TABLE, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.transformTableSuccess(data.data));
        window.location.reload(true);
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.transformTableFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getPageImage = createLogic({
  type: types.GET_PAGE_IMAGE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running GET_PAGE_IMAGE Service");
    HTTPClient.Get(endPoints.PAGE_IMAGE + `/${action.payload}`)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getPageImageSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.getPageImageFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getPage = createLogic({
  type: types.GET_PAGE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running GET_PAGE Service");
    HTTPClient.Get(endPoints.PAGE + `/${action.payload.pageNo}`)
      .then((resp) => resp.data)
      .then((data) => {
        sessionStorage.setItem("numOfPages", data?.data?.numberOfPages);
        dispatch(actions.getPageSuccess(data?.data));
        // dispatch(actions.getPageImage(data?.data?.id));
        // history.push(`/validate/${action?.payload?.pageNo}`);
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.getPageFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const updateTable = createLogic({
  type: types.UPDATE_TABLE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running UPDATE_TABLE Service");
    HTTPClient.Post(endPoints.UPDATE_TABLE, action.payload.tableData)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.updateTableSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.updateTableFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const skip = createLogic({
  type: types.SKIP,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running SKIP Service");
    HTTPClient.Get(endPoints.GENERATE_DOCUMENT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.skipSuccess(data));

        history.push(`/results`);
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.skipFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getAllDocumentCount = createLogic({
  type: types.GET_ALL_DOCUMENT_COUNT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running GET_ALL_DOCUMENT_COUNT Service");
    HTTPClient.Get(endPoints.DOCUMENT_COUNT_ALL)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getAllDocumentCountSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.getAllDocumentCountFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addFeedback = createLogic({
  type: types.ADD_FEEDBACK,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running ADD_FEEDBACK Service");
    HTTPClient.Post(endPoints.ADD_FEEDBACK, action.payload.feedback)
      .then((resp) => resp.data)
      .then((data) => {
        Alerts.showSuccess("Thank you for your Feedback");
        dispatch(actions.addFeedbackSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.addFeedbackFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const deleteDocument = createLogic({
  type: types.DELETE_DOCUMENT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Delete(endPoints.DOCUMENT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.deleteDocumentSuccess({ data: data }));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.deleteDocumentFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const uploadCorrections = createLogic({
  type: types.UPLOAD_CORRECTIONS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running UPLOAD_CORRECTIONS Service");
    HTTPClient.Upload(
      endPoints.DOCUMENT_CORRECTIONS_UPLOAD,
      action.payload.formData
    )
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.uploadCorrectionsSuccess(data));
        action.payload.callBack(endPoints.DOCUMENT_CORRECTIONS_DOWNLOAD);
      })

      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.uploadCorrectionsFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addTableHeading = createLogic({
  type: types.ADD_TABLE_HEADING,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running ADD_TABLE_HEADING Service");
    HTTPClient.Put(
      endPoints.ADD_TABLE_HEADING,
      action.payload.addTableHeadingDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.addTableHeadingSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.addTableHeadingFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const transposeTable = createLogic({
  type: types.TRANSPOSE_TABLE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running TRANSPOSE_TABLE Service");
    HTTPClient.Get(
      endPoints.TRANSPOSE_TABLE_URL +
      `?pageNo=${action.payload.pageNo}&tableId=${action.payload.tableId}`
    )
      .then((resp) => resp.data)
      .then((data) => {
        data?.data?.warning_msg !== undefined && data?.data?.warning_msg !== null && Alerts.showWarning(data?.data?.warning_msg);
        dispatch(actions.transposeTableSuccess(data?.data));
        window.location.reload(true);
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.transposeTableFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getAllConfigs = createLogic({
  type: types.GET_ALL_CONFIGS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running GET_ALL_CONFIGS Service");
    HTTPClient.Get(endPoints.GET_ALL_CONFIGS)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getAllConfigsSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.getAllConfigsFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addConfig = createLogic({
  type: types.ADD_CONFIG,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running ADD_CONFIG Service");
    HTTPClient.Post(endPoints.ADD_CONFIG, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        Alerts.showSuccess("Selections Saved");
        dispatch(actions.addConfigSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.addConfigFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const updateConfig = createLogic({
  type: types.UPDATE_CONFIG,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running UPDATE_CONFIG Service");
    HTTPClient.Put(endPoints.UPDATE_CONFIG)
      .then((resp) => resp.data)
      .then((data) => {
        Alerts.showSuccess("Selections updated");
        dispatch(actions.updateConfigSuccess(data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.updateRowFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const deleteConfig = createLogic({
  type: types.DELETE_CONFIG,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running DELETE_CONFIG Service", action.payload);
    HTTPClient.Delete(endPoints.DELETE_CONFIG, action.payload)
      .then((resp) => resp.data)
      .then((res) => {
        dispatch(actions.deleteConfigSuccess({ orderTemplateId: action.payload.orderTemplateId, data: res.data }));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        Alerts.showError(errorMessage);
        dispatch(
          actions.deleteConfigFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getMetadata = createLogic({
  type: types.GET_METADATA,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running GET_METADATA Service");
    HTTPClient.Get(endPoints.GET_METADATA)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getMetaDataSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.getMetaDataFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getMetadataSelection = createLogic({
  type: types.GET_METADATA_SELECTION,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running GET_METADATA_SELECTION Service");
    HTTPClient.Get(endPoints.GET_METADATA_SELECTION)
      .then((resp) => resp.data)
      .then((data) => {
        if (data?.data?.selectionStatus) {
          dispatch(actions.changeValidateStatus({
            isSelectItem: true,
          }));
        } else {
          dispatch(actions.changeValidateStatus({
            isSelectItem: false,
          }));
        }
        dispatch(actions.getMetaDataSelectionSuccess(data.data));
      })
      .catch((err) => {
        dispatch(actions.changeValidateStatus({
          isSelectItem: false,
        }));
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.getMetaDataSelectionFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getMetadataValidatePage = createLogic({
  type: types.GET_METADATA_VALIDATE_PAGE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    console.log("Running GET_METADATA_VALIDATION_PAGE Service");
    HTTPClient.Get(endPoints.GET_METADATA_VALIDATE_PAGE)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getMetaDataValidatePageSuccess(data.data));
        if (!data?.data?.page_available) {

          dispatch(actions.changeValidateStatus({
            isValidatePage: false,
            isSelectItem: false
          }));

          // if(action.payload.isNavigateToUpload){
          //   history.push('/upload');
          // }
        } else {
          dispatch(actions.changeValidateStatus({
            isValidatePage: true,
          }));
          action.payload.callback();
        }
      })
      .catch((err) => {
        var errorMessage = err?.response?.data || "Something went wrong!";
        dispatch(
          actions.getMetaDataValidatePageFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

var services = [
  updateKeyValue,
  updateRow,
  addKeyValue,
  addTable,
  addColumn,
  uploadPDF,
  uploadConfig,
  getFileCount,
  getTableHeadings,
  downloadResult,
  startPredict,
  getProgress,
  resizeElement,
  deleteElement,
  deleteTable,
  transformTable,
  getPageImage,
  getPage,
  updateTable,
  skip,
  getAllDocumentCount,
  updateTableHeading,
  updateSelectedKeyValue,
  addHeadingTempplate,
  addHeadingTemplateForTable,
  addFeedback,
  deleteDocument,
  uploadCorrections,
  addTableHeading,
  transposeTable,
  getAllConfigs,
  addConfig,
  updateConfig,
  deleteConfig,
  getMetadata,
  getMetadataSelection,
  getMetadataValidatePage,
  getPreviewResult,
  downloadPreviewResult,
  skipGptRetry,
  retryGptGeneration
];

export default services;
