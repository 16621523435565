import React, { useEffect } from "react";
import { Document, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfPageCounter({
  fileDetails,
  acceptedFiles,
  callBack,
  setTotalNumPages,
  isValid,
  pageUpdateHandler,
  prevFileDetailsLength
}) {


  useEffect(() => {
    isValid && callBack(acceptedFiles);
  }, [acceptedFiles]);

  const onDocumentLoadSuccess = (index, { numPages }) => {
    pageUpdateHandler(index, numPages);
    setTotalNumPages(fileDetails.map(value => value?.numPages || 1).reduce((partialSum, a) => partialSum + a, 0));
  };

  return (
    <div>
      {fileDetails.length >= 0 && fileDetails.length >= prevFileDetailsLength && (
        <div>
          {fileDetails.map((file, index) => {
            return (
              <div key={index}>

                <Document
                  loading={null}
                  file={file}
                  onLoadSuccess={(event) =>
                    onDocumentLoadSuccess(index, event)
                  }
                >
                </Document>
              </div>
            )
          })}
        </div>)}</div>
  );
}
