import React from "react";

const NoDashboardLayout = (ViewComponent) => {
  return class extends React.Component {
    render() {
      return (
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-12 h-100">
              <ViewComponent />
            </div>
          </div>
        </div>
      );
    }
  };
};

export default NoDashboardLayout;
