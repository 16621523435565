import React, { useEffect, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { PricingCard, PricingCardProps, PricingFeature } from './PricingCard/PricingCard'
import { IOSSwitch } from './PeriodToggler/PeriodToggler';
import './PricingPage.css'
import { FAQ } from 'components/modal/FAQ';
import { connect, useDispatch } from 'react-redux';
import { AuthActions } from 'modules/auth/ducks';
import { selectedSubscriptionPlan } from 'modules/auth/ducks/selectors';
import types from 'modules/auth/ducks/types';
import { PaymentStatusModal } from './PaymentStatusModal/PaymentStatusModal';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1280,
        xl: 1440,
    },
};
const theme = createTheme({ breakpoints });

type PricingPlanType = "Free" | "Pro" | "Enterprise"

interface PricingPlan extends PricingCardProps {
    planType: PricingPlanType
}

interface PlanFeature extends PricingFeature {
    plans: PricingPlanType[];
}

const yearlySavePercentage = 35

interface PendingAction {
    actions: string[]
    loading: boolean
}


interface ProPlanFeature {
    id: number
    name: string
    description: string
    interval: [{
        billing_interval: "monthly" | "yearly"
        currency: string
        id: number
        maximum_pages: number
        plan_id: number
        price: number
        stripe_price_id: string,
        active: boolean
    }[]]
    stripe_product_id: string
}

export const pricingPlanFeaturesOther: PlanFeature[] = [
    { title: "20 Pages / month", plans: ["Free"], available: true },
    { title: "OCR, AI Table & key-value-pair, GPT Recognition", plans: ["Free", "Pro"], available: true },
    { title: "Self Set-up", plans: ["Free", "Pro"], available: true },
    // { title: "400 Pages / month", plans: ["Pro"], attributes: { type: 'radio', price: 10, onClick: () => { }, radioGroupId: "pages" }, available: true },
    // { title: "1000 Pages / month", plans: ["Pro"], attributes: { type: 'radio', price: 20, onClick: () => { }, radioGroupId: "pages" }, available: true },
    // { title: "2500 Pages / month", plans: ["Pro"], attributes: { type: 'radio', price: 30, onClick: () => { }, radioGroupId: "pages" }, available: true },
    { title: "Support", plans: ["Pro"], available: true },
    { title: "Custom Pages / month", plans: ["Enterprise"], available: true },
    { title: "Custom Recognition", plans: ["Enterprise"], available: true },
    { title: "Full Set up", plans: ["Enterprise"], available: true },
    { title: "Premium Support", plans: ["Enterprise"], available: true },
    { title: "Full Document Extraction Service", plans: ["Enterprise"], available: true },
]

const getPlanFeatures = (planType: PricingPlanType, pricingPlanFeatures: PlanFeature[]): PricingFeature[] => {
    const features: PricingFeature[] = []
    pricingPlanFeatures.forEach(({ plans, ...others }) => {
        if (plans.includes(planType)) {
            features.push({ ...others })
        }
    })
    return features
}

const PricingPageX = ({ proPlanFeatures, pendingActions }: { proPlanFeatures: ProPlanFeature[], pricingPlan?: string | null, pendingActions: PendingAction }) => {

    const [pricingPlanFeatures, setPricingPlanFeatures] = useState<PlanFeature[]>([])
    const [openReturnModal, setOpenReturnModal] = useState(false);
    const [_returnStatus, setReturnStatus] = useState<"success" | "cancel">("success");
    const [isMonthly, setIsMonthly] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if (window.location.search.includes("checkout=success")) {
            dispatch(AuthActions.confirmSubscription());
            setReturnStatus("success")
            setOpenReturnModal(true)
        }

        // clear if any query params
        window.history.replaceState({}, document.title, "/pricing-plans");
    }, [])

    const isPricingCardLoading = (type: string): boolean => {
        return pendingActions.actions.includes(type) && pendingActions.loading
    }

    const pricingPlans: PricingPlan[] = [
        {
            title: "Free",
            monthlyPrice: 0,
            yearlyPrice: 0,
            description: "For Individuals & to try out the platform's features",
            features: getPlanFeatures("Free", pricingPlanFeatures),
            planType: "Free",
            buttonText: "Try Free",
            hideBillingPeriod: true,
            onClick: () => {
                dispatch(AuthActions.subscribeFreePlan())
            },
            isBtnLoading: isPricingCardLoading(types.SUBSCRIBE_FREE_PLAN),
        },
        {
            title: "Pro",
            description: "For Businesses with some data extraction need",
            monthlyPrice: 10,
            yearlyPrice: 12.99,
            onClick: () => window.open("https://app.docutize.ai", "_self"),
            features: getPlanFeatures("Pro", pricingPlanFeatures),
            planType: "Pro",
            buttonText: "Subscribe Now",
            background: "rgba(241, 243, 250, 1)",
            btnClassName: "custom-subs-btn",
            isBtnLoading: isPricingCardLoading(types.SUBSCRIBE_PRICING_PLAN),
        },
        {
            title: "Enterprise",
            description: "For Enterprises with large data extraction need",
            monthlyPrice: 10,
            yearlyPrice: 8,
            onClick: () => window.open("https://calendly.com/docutizeai/30min", "_blank"),
            features: getPlanFeatures("Enterprise", pricingPlanFeatures),
            planType: "Enterprise",
            buttonText: "Schedule a Call",
            customPrice: "Custom",
            customBillingPeriodText: "yearly billing only",
            hideSymbol: true
        },
    ]

    useEffect(() => {
        const features: PlanFeature[] = []
        proPlanFeatures?.length > 0 && proPlanFeatures.forEach((proPlanFeature) => {
            const feature = proPlanFeature.interval[0]
                .filter(interval => (isMonthly && interval.billing_interval === "monthly") || (!isMonthly && interval.billing_interval === "yearly"))
            feature.forEach((interval) => {
                features.push({
                    title: `${interval.maximum_pages.toLocaleString()} Pages / ${isMonthly ? "month" : "year"}`,
                    plans: ["Pro"],
                    attributes: {
                        active: interval.active,
                        type: 'radio',
                        price: interval.price,
                        onClick: () => {
                            const planIntervalId = interval.id
                            dispatch(AuthActions.subscribePricingPlan({ planIntervalId }))
                        },
                        radioGroupId: "pages_" + isMonthly ? "monthly" : "yearly"
                    },
                    available: true
                })
            })
        })
        setPricingPlanFeatures([...pricingPlanFeaturesOther, ...features])
    }, [proPlanFeatures, isMonthly])

    return (
        <div className='pricing-page'>

            <div className="pricing-heading">
                Select a Plan
            </div>

            <div className='period-toggler-container'>
                <div className={`billing-period-text me-3`}>Billed Monthly</div>
                <div>
                    <FormControlLabel
                        style={{ margin: 0 }}
                        control={<IOSSwitch onChange={() => setIsMonthly(!isMonthly)} defaultChecked checked={!isMonthly} />}
                        label=""
                    />
                </div>
                <div className={`billing-period-text ms-3 ${!isMonthly && 'active'}`}>Billed Yearly (Save up to {yearlySavePercentage}%)</div>
            </div>

            <div className='pricing-cards-container' >
                <Grid container spacing={2}>
                    {pricingPlans.map((plan, index) => {
                        return (
                            <ThemeProvider theme={theme} key={`pricing-plan-${index}`}>
                                <Grid item xs={12} md={4} display={'flex'} justifyContent={"center"}>
                                    <PricingCard
                                        btnClassName={plan.btnClassName}
                                        background={plan.background}
                                        customBillingPeriodText={plan.customBillingPeriodText}
                                        hideBillingPeriod={plan.hideBillingPeriod}
                                        customPrice={plan.customPrice}
                                        buttonText={plan.buttonText}
                                        description={plan.description}
                                        features={plan.features}
                                        monthlyPrice={plan.monthlyPrice}
                                        title={plan.title}
                                        yearlyPrice={plan.yearlyPrice}
                                        mode={isMonthly ? "monthly" : "yearly"}
                                        onClick={plan.onClick}
                                        hideSymbol={plan.hideSymbol}
                                        isBtnLoading={plan.isBtnLoading}
                                    />
                                </Grid>
                            </ThemeProvider>
                        )
                    }
                    )}
                </Grid>
            </div>

            <div className='faq-container'>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} md={10} sm={12}>
                        <p className="mb-5 text-center">*Applicable Taxes may apply</p>
                    <p className="mb-5"></p>
                        <p className="mb-5">
                        <FAQ /></p>
                        <p className="mb-5">If you have any other questions use the Support Chat, <a style={{color: '#0029FF'}} href="https://calendly.com/docutizeai/30min" target="_blank">Schedule a Call</a> or 
          email to <a style={{color: '#0029FF'}} href="mailto:support@docutize.ai">support@docutize.ai</a></p>
                    </Grid>
                </Grid>
            </div>

            <PaymentStatusModal open={openReturnModal} handleClose={() => setOpenReturnModal(true)} />
        </div>
    )
}


function mapStateToProps(state) {
    return {
        proPlanFeatures: state.Auth.pricingPlans.data,
        pendingActions: state.Auth.pendingActions,
        pricingPlan: selectedSubscriptionPlan(state)
    };
}

export const PricingPage = connect(mapStateToProps, null)(PricingPageX);