import React from "react";
import { useTranslation } from "react-i18next";

type TranslateMessageProps = {
  translateKey: string;
  data?: any;
}

export function TranslateMessage(props: TranslateMessageProps) {
  const { translateKey, data = {} } = props;
  const { t } = useTranslation();

  return <>{t(translateKey, { ...data })}</>;
}

export default TranslateMessage;
