// Actions
import { createAction } from "redux-actions";
import types from "./types";

const actions = {
  signIn: createAction(types.SIGN_IN),
  signInSuccess: createAction(types.SIGN_IN_SUCCESS),
  signInFail: createAction(types.SIGN_IN_FAILED),

  signUp: createAction(types.SIGN_UP),
  signUpSuccess: createAction(types.SIGN_UP_SUCCESS),
  signUpSpinnerCompleted: createAction(types.SIGN_UP_SPINNER_COMPLETED),
  signUpFail: createAction(types.SIGN_UP_FAILED),

  getPricingData: createAction(types.GET_PRICING_DATA),
  getPricingDataSuccess: createAction(types.GET_PRICING_DATA_SUCCESS),
  getPricingDataFail: createAction(types.GET_PRICING_DATA_FAILED),

  subscribePricingPlan: createAction(types.SUBSCRIBE_PRICING_PLAN),
  subscribePricingPlanSuccess: createAction(types.SUBSCRIBE_PRICING_PLAN_SUCCESS),
  subscribePricingPlanFail: createAction(types.SUBSCRIBE_PRICING_PLAN_FAILED),

  confirmSubscription: createAction(types.CONFIRM_SUBSCRIPTION),
  confirmSubscriptionSuccess: createAction(types.CONFIRM_SUBSCRIPTION_SUCCESS),
  confirmSubscriptionFail: createAction(types.CONFIRM_SUBSCRIPTION_FAILED),
  
  subscribeFreePlan: createAction(types.SUBSCRIBE_FREE_PLAN),
  subscribeFreePlanSuccess: createAction(types.SUBSCRIBE_FREE_PLAN_SUCCESS),
  subscribeFreePlanFail: createAction(types.SUBSCRIBE_FREE_PLAN_FAILED),

  pendingActionStarted: createAction(types.PENDING_ACTION_STARTED),
  pendingActionCompleted: createAction(types.PENDING_ACTION_COMPLETED),

  getUserPortalPaymentInfo: createAction(types.GET_USER_PORTAL_PAYMENT_INFO),
  getUserPortalPaymentInfoSuccess: createAction(types.GET_USER_PORTAL_PAYMENT_INFO_SUCCESS),
  getUserPortalPaymentInfoFail: createAction(types.GET_USER_PORTAL_PAYMENT_INFO_FAILED),

  signOut: createAction(types.SIGN_OUT),
  signOutSuccess: createAction(types.SIGN_OUT_SUCCESS),
  signOutFail: createAction(types.SIGN_OUT_FAILED),

  userInfo: createAction(types.USER_INFO),
  userInfoSuccess: createAction(types.USER_INFO_SUCCESS),
  userInfoFail: createAction(types.USER_INFO_FAILED),

  changePassword: createAction(types.CHANGE_PASSWORD),
  changePasswordSuccess: createAction(types.CHANGE_PASSWORD_SUCCESS),
  changePasswordFail: createAction(types.CHANGE_PASSWORD_FAILED),

  getUserInfo: createAction(types.GET_USER_INFO),
  getUserInfoSuccess: createAction(types.GET_USER_INFO_SUCCESS),
  getUserInfoFail: createAction(types.GET_USER_INFO_FAILED),

  deleteUser: createAction(types.DELETE_USER),
  deleteUserSuccess: createAction(types.DELETE_USER_SUCCESS),
  deleteUserFail: createAction(types.DELETE_USER_FAILED),

  updateUser: createAction(types.UPDATE_USER),
  updateUserSuccess: createAction(types.UPDATE_USER_SUCCESS),
  updateUserFail: createAction(types.UPDATE_USER_FAILED),

  checkEmail: createAction(types.CHECK_EMAIL),
  checkEmailSuccess: createAction(types.CHECK_EMAIL_SUCCESS),
  checkEmailFail: createAction(types.CHECK_EMAIL_FAILED),

  resetPassword: createAction(types.RESET_PASSWORD),
  resetPasswordSuccess: createAction(types.RESET_PASSWORD_SUCCESS),
  resetPasswordFail: createAction(types.RESET_PASSWORD_FAILED),

  setSession: createAction(types.SET_SESSION),

  updateUserInfo: createAction(types.UPDATE_USER_INFO),

};

export default actions;
