import React, { Component } from "react";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CircularProgress } from "@mui/material";
import { AuthActions } from "../auth/ducks";
import {
  CheckedCheckBoxIcon,
  EmptyCheckBoxIcon,
} from "../../components/shared/CustomComponents";
import TranslateMessage from "../../components/translateManager/TranslateMessage";


/**
 * 
 *
 * @component
 * @example
 * // Usage example:
 * <DeleteAccount />
 *
 * @returns {JSX.Element} The JSX element representing the DeleteAccount component.
 * 
 */
class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAggred: false,
    };
  }
  
  /**
   * Check box handler
   * 
   * @function
   * @description Set the state according to check box state.
   */
  checkboxHandler = () => {
    this.setState({
      isAggred: !this.state.isAggred,
    });
  };

  /**
   * Delete user handler
   * 
   * @function
   * @description Handles the deletion of the user account.
   */
  deleteHandler = () => {
    this.props.AuthActions.deleteUser();
  };

  render() {
    const { isAggred } = this.state;
    const { deleteUser } = this.props;
    return (
      <div className="mail-area">
        <div className="row mt-4">
          <div className="col-md-12 col-lg-8 col-xl-7 col-xxl-6 px-4 pb-lg-4 extra-pading">
            <div className="row">
              <div className="col-md-12 mt-4">
                <div className="mb-5" style={{ color: "#002aff" }}>
                  <TranslateMessage translateKey="delete_profile_description" />
                </div>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          onChange={this.checkboxHandler}
                          checked={isAggred}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 26,
                              color: "#002aff",
                            },
                          }}
                          icon={<EmptyCheckBoxIcon />}
                          checkedIcon={<CheckedCheckBoxIcon />}
                        />
                      }
                      label={
                        <TranslateMessage translateKey="delete_profile_confirmation" />
                      }
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 col-lg-8 col-xl-7 col-xxl-6 px-4 text-end">
            <Button
              className="blue-btn mt-2 mb-3"
              variant="contained"
              disabled={!isAggred}
              onClick={this.deleteHandler}
            >
              {!deleteUser.pending ? (
                <TranslateMessage translateKey="delete_account" />
              ) : (
                <CircularProgress className="loading-btn-circle" />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    deleteUser: state.Auth.deleteUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AuthActions: bindActionCreators(AuthActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeleteAccount)
);
