import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthActions } from "../../modules/auth/ducks";
import { PredictActions } from "../../modules/validate/ducks";
import Tour from 'reactour';
import { productTourConfig } from "config/product-tour-config";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CloudUploadOutlined from "@mui/icons-material/CloudUploadOutlined";
import PlagiarismOutlined from "@mui/icons-material/PlagiarismOutlined";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import TranslateMessage from "../translateManager/TranslateMessage";
// import LanguageIcon from "@mui/icons-material/Language";
import i18next from "i18next";
import cookies from "js-cookie";
import HomeLogo from "assets/images/logo-home2.svg";
import ToolTip from "./ToolTip";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isEnabledDownload: false,
      isTourOpen: props.productTour === true,
      isShowingMore: false
    };
  }

  componentDidMount() {

    const { userInfo, productTour } = this.props;

    const isNavigateToUpload = this.props.history?.location?.pathname?.includes('/validate');
    this.props.predictActions.getMetaDataSelection();
    this.props.predictActions.getMetaDataValidatePage({ callback: this.enableDownload, isNavigateToUpload: isNavigateToUpload });
    this.props.predictActions.getAllDocumentCount();

    if(userInfo?.data?.data?.user?.product_tour_viewed !== undefined && (!userInfo?.data?.data?.user?.product_tour_viewed || productTour) && isNavigateToUpload) {
      
      this.HandleTourOpenChange();
      let userInfoDto = {
        key: 'product_tour_viewed',
        value: 'true',
      }

      this.props.AuthActions.updateUserInfo({ userInfoDto });

    }
  }

  // when tour is closed update the productTour=false
  HandleTourOpenChange = () => {
    setTimeout(() => {
      this.props.predictActions.clickProductTour();
    }, 1000);
  }

  closeTour = () => {
    setTimeout(() => {
      this.props.predictActions.clickProductTour();
    }, 100);
  };

  enableDownload = () => {
    this.setState({
      isEnabledDownload: true
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isTourOpen !== prevState.isTourOpen) {
      // to change the status of the productTour
      this.HandleTourOpenChange();
    }
  }

  handleClick = (event) => {
    // debugger;
    this.setState({
      anchorEl: !!this.state.anchorEl ? null : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  signoutHandler = () => {
    this.props.AuthActions.signOut();
  };

  handleListItemClick = (event, url, index) => {
    if (index !== 7) {
      this.props.history.push(url);
    }
    if (index === 7) {
      this.signoutHandler();
    }
  };

  languageHandler = (event) => {
    console.log(" languageHandler ", event.currentTarget);
  };

  handlePopoverOpen = (event) => {
    // setAnchorEl(event.currentTarget);
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handlePopoverClose = () => {
    // setAnchorEl(null);
    this.setState({
      anchorEl: null,
    });
  };

  languageSelectHandler = (data) => {
    cookies.set("i18next", data);
    i18next.changeLanguage(data);
  };

  render() {
    // product tour color of step dots and numbers 
    const accentColor = "#5cb7b7";
    // const { anchorEl } = this.state;
    const { pathname } = this.props.location;
    const splitLocation = pathname.split("/");
    const { getAllDocumentCount, validateStatus, userInfo } = this.props;
    // const open = Boolean(anchorEl);
    // const popId = open ? "simple-popover" : undefined;
    const emailFirstLetter = userInfo?.data?.data?.user?.email?.[0];

    return (
      <div className="side-bar d-flex flex-column justify-content-between p-1">
        <div>
          <div className="doc">
            <img src={HomeLogo} alt="Home Logo" width="100%" height="100%" />
          </div>
          <div className="d-flex flex-column align-items-center pt-4 pb-1">
            <div className="avatar-circle d-flex flex-column align-items-center justify-content-center">
              <div className="name d-inline-block text-truncate text-uppercase">
                {emailFirstLetter}
              </div>
              <div className="count">
                {getAllDocumentCount?.data?.data?.maxLimit -
                  getAllDocumentCount?.data?.data?.count || 0}
              </div>
            </div>
          </div>
        </div>
        <div>
          <List component="nav">
            <ListItemButton
              disableRipple={true}
              selected={splitLocation[1] === "upload"}
              onClick={(event) => this.handleListItemClick(event, "/upload", 0)}
            >
              <ListItemIcon>
                <CloudUploadOutlined />
              </ListItemIcon>
              <ListItemText
                primary={<TranslateMessage translateKey="upload" />}
              />
            </ListItemButton>

            {validateStatus.isValidatePage ?
              <ListItemButton
                disableRipple={true}
                selected={splitLocation[1] === "validate"}
                onClick={(event) =>
                  this.handleListItemClick(event, "/validate/1", 1)
                }
              >
                <ListItemIcon>
                  <PlagiarismOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={<TranslateMessage translateKey="select" />}
                />
              </ListItemButton>

              :

              <ToolTip title={"Upload Data First"}
                imgUrl={null}
                isBtn={true}
                children={
                  <span>
                    <ListItemButton
                      disabled={true}
                      disableRipple={true}
                      selected={splitLocation[1] === "validate"}
                      onClick={(event) =>
                        this.handleListItemClick(event, "/validate/1", 1)
                      }
                    >
                      <ListItemIcon>
                        <PlagiarismOutlined />
                      </ListItemIcon>
                      <ListItemText
                        primary={<TranslateMessage translateKey="select" />}
                      />
                    </ListItemButton>
                  </span>
                }
              />
            }

            {validateStatus.isSelectItem ?
              <ListItemButton
                disableRipple={true}
                selected={splitLocation[1] === "results"}
                onClick={(event) =>
                  this.handleListItemClick(event, "/results", 2)
                }
              >
                <ListItemIcon>
                  <CloudDownloadOutlinedIcon />
                </ListItemIcon>

                <ListItemText
                  primary={<TranslateMessage translateKey="download" />}
                />
              </ListItemButton>
              :
              <ToolTip
                title={"First select Data by clicking the Checkbox"}
                imgUrl={null}
                isBtn={true}
                children={
                  <span>
                    <ListItemButton
                      disabled={true}
                      disableRipple={true}
                      selected={splitLocation[1] === "results"}
                      onClick={(event) =>
                        this.handleListItemClick(event, "/results", 2)
                      }
                    >
                      <ListItemIcon>
                        <CloudDownloadOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={<TranslateMessage translateKey="download" />}
                      />
                    </ListItemButton>
                  </span>
                } />
            }

            {/* <ListItemButton
              disableRipple={true}
              selected={splitLocation[1] === "word-counter"}
              onClick={(event) =>
                this.handleListItemClick(event, "/word-counter", 3)
              }
            >
              <ListItemIcon>
                <SpellcheckIcon />
              </ListItemIcon>
              <ListItemText
                primary={<TranslateMessage translateKey="word_corrector" />}
              />
            </ListItemButton> */}
          </List>
        </div>
        <div>
          <List component="nav">
            <ListItemButton
              disableRipple={true}
              selected={splitLocation[1] === "account"}
              onClick={(event) =>
                this.handleListItemClick(event, "/account", 4)
              }
            >
              <ListItemIcon>
                <AccountCircleOutlined />
              </ListItemIcon>
              <ListItemText
                primary={<TranslateMessage translateKey="account" />}
              />
            </ListItemButton>
            {/* <ListItemButton
              disabled={true}
              selected={splitLocation[1] === "help"}
              onClick={(event) => this.handleListItemClick(event, "/help", 5)}
            >
              <ListItemIcon>
                <HelpOutlineOutlined />
              </ListItemIcon>
              <ListItemText
                primary={<TranslateMessage translateKey="help" />}
              />
            </ListItemButton> */}
            {/* <ListItemButton
              aria-describedby={popId}
              selected={splitLocation[1] === "language"}
              onClick={(event) => this.handleClick(event)}
            >
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText
                primary={<TranslateMessage translateKey="language" />}
              />
              <Popover
                id={popId}
                open={open}
                anchorEl={anchorEl}
                onClose={() => this.handleClose()}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography
                  className="lang-text-side"
                  onClick={() =>
                    this.languageSelectHandler(DEUTSCH_LANGUAGE_CODE)
                  }
                  sx={{ px: 2, py: 1 }}
                >
                  Deutsch{" "}
                </Typography>
                <Typography
                  className="lang-text-side"
                  onClick={() =>
                    this.languageSelectHandler(ENGLISH_LANGUAGE_CODE)
                  }
                  sx={{ px: 2, py: 1 }}
                >
                  English{" "}
                </Typography>
              </Popover>
            </ListItemButton> */}

            {/* <ListItemButton
              aria-describedby={popId}
              selected={splitLocation[1] === "language"}
              onClick={(event) => this.handleClick(event)}
            >
              <Typography
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={this.handlePopoverOpen}
                onMouseLeave={this.handlePopoverClose}
              >
                <ListItemIcon>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText primary={" Hover with a Popover."} />
              </Typography>

              <Popover
                // id={id}
                id={popId}
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography sx={{ p: 2 }}>
                  The content of the Popover.
                </Typography>
              </Popover>
            </ListItemButton> */}
            <ListItemButton
              selected={splitLocation[1] === "logout"}
              onClick={(event) => this.handleListItemClick(event, "", 7)}
            >
              <ListItemIcon>
                <LogoutOutlined />
              </ListItemIcon>
              <ListItemText
                primary={<TranslateMessage translateKey="logout" />}
              />
            </ListItemButton>
          </List>
        </div>
        <Tour
            onRequestClose={this.closeTour}
            steps={productTourConfig}
            isOpen={this.props.productTour}
            rounded={10}
            accentColor={accentColor}
            closeWithMask={false}
          />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    predict: state.Predict,
    detections: state.Predict.detections,
    appMode: state.Predict.appMode,
    selectedDropdownElement: state.Predict.selectedDropdownElement,
    pageImage: state.Predict.pageImage,
    viewMode: state.Predict.viewMode,
    getAllDocumentCount: state.Predict.getAllDocumentCount,
    userInfo: state.Auth.userInfo,
    getTableHeadings: state.Predict.getTableHeadings,
    getMetaDataSelection: state.Predict.getMetaDataSelection,
    getMetaDataValidatePage: state.Predict.getMetaDataValidatePage,
    validateStatus: state.Predict.validateStatus,
    productTour: state.Predict.productTour,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AuthActions: bindActionCreators(AuthActions, dispatch),
    predictActions: bindActionCreators(PredictActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideBar)
);