
import React from 'react'
import Modal from '@mui/material/Modal';
import "./GPTDataSelectDialog.css"
import { Button, FormControlLabel, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { IOSSwitch } from 'modules/pricing/PeriodToggler/PeriodToggler';

interface GPTDataSelectDialogProps {
    open: boolean,
    handleUploadStart: (data: { skipped: boolean }) => void,
    handleStart: (llmEnabled: boolean, llmKeys: string[]) => void,
    isBtnLoading?: boolean,
    handleSkip: () => void
}

export const GPTDataSelectDialog = ({ handleSkip, open, isBtnLoading, handleStart, handleUploadStart }: GPTDataSelectDialogProps) => {

    const [inputRefs, setInputRefs] = React.useState<any[]>([])
    const [llmEnabled, setLlmEnabled] = React.useState<boolean>(true)

    React.useEffect(() => {
        // set input refs
        setInputRefs(new Array(6).fill(0).map(() => React.createRef()))
    }, [])

    const addMoreFields = () => {
        // create 3 more input refs
        const newRefs = new Array(3).fill(0).map(() => React.createRef())
        setInputRefs([...inputRefs, ...newRefs])
    }

    const handleSubmit = () => {
        handleStart(llmEnabled, inputRefs.map(ref => ref.current.value).filter(value => value));

        setTimeout(() => {
            handleUploadStart({ skipped: true });
        }, 500);
    }

    const getPlaceholder = (index : number) => {
        switch (index){
            case 1:
                return 'Order number';
            case 2:
                return 'Order arrival date';
            case 3:
                return 'delivery address';
            default:
                return '';
        }
    }

    return (
        <>
            <Modal
                disableEscapeKeyDown={true}
                open={open}
                onClose={handleSkip}
                BackdropProps={{ onClick: (event) => event.stopPropagation() }}
            >
                <div className='gpt-data-select-modal'>
                    <div className='modal-header border-0 pb-0'>
                        <div></div>
                        <div>
                            <Tooltip title={<span style={{ fontSize: 15 }}>{"The GPT service is provided by third parties. GPT can extract specific Text values. Table & key-value-pair extraction can also be used without GPT. If you Skip GPT now, you can not use it later for this upload."}</span>}>
                                <InfoOutlinedIcon sx={{ color: 'black', fontSize: "26px" }}></InfoOutlinedIcon>
                            </Tooltip>
                        </div>
                    </div>
                    <div className='modal-body'>
                        <div className='title-container'>
                            <div className='title'>Which Text Data do you need to extract?</div>
                            <div className='toggler-container'>
                                <div>
                                    <FormControlLabel
                                        style={{ margin: 0 }}
                                        control={<IOSSwitch onChange={() => { setLlmEnabled(!llmEnabled) }} defaultChecked checked={llmEnabled} />}
                                        label=""
                                    />
                                </div>
                                <div className={`using-gpt-text ms-3`}>Using GPT</div>
                            </div>
                        </div>

                        <div className='data-adding-container'>
                            <div className='place-holder-text'>Input the Text Labels you want to find using GPT 
                            <Tooltip title={<span style={{ fontSize: 15 }}>{"Text Labels describe specific Text values like Home Address, Work Email, etc. Fewer Text Labels and more precise Labels result in better findings."}</span>}>
                                <HelpOutlineOutlinedIcon sx={{ color: 'black', fontSize: "26px" }}></HelpOutlineOutlinedIcon>
                            </Tooltip></div>

                            <div className='gpt-inputs-container'>
                                {inputRefs.map((_inputRef, index) => {
                                    if (index % 3 === 0) {
                                        return (
                                            <div className='gpt-input-row' key={index}>
                                                {inputRefs.slice(index, index + 3).map((ref, innerIndex) => (
                                                    <div className='gpt-input-box' key={innerIndex}>
                                                        <div className='ipb-number'>{index + innerIndex + 1}</div>
                                                        <div>
                                                            <input
                                                                ref={ref}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={getPlaceholder(index + innerIndex + 1)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                            <div onClick={addMoreFields} className='add-more-field-btn'>+ Add more fields</div>

                            <div className='btns-container'>
                                <div style={{ width: 200 }}></div>

                                <Button onClick={handleSubmit} className={`start-btn`}>
                                    Start
                                    {isBtnLoading && <CircularProgress size={16} style={{ color: "inherit", marginLeft: 10 }} />}
                                </Button>

                                <Button onClick={handleSkip} className={`skip-btn`}>
                                    Skip GPT
                                    {isBtnLoading && <CircularProgress size={16} style={{ color: "inherit", marginLeft: 10 }} />}
                                </Button>

                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )
}

