import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PredictActions } from '../validate/ducks';
import UploadedStatusImg from '../../assets/images/file.png';
import PdfUpload from './PdfUpload';
import { PDF_UPLOAD } from '../../util/Constants';
import UploadLoadingManager from '../../components/loading/UploadLoadingManager';
import ConfigScreen from './ConfigScreen/index';
import { io } from "socket.io-client";
import { GPTDataSelectDialog } from "./GPTDataSelectDialog/GPTDataSelectDialog";

// Define socket in upload page
let socketUpload = '';

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGptModalOpen: false,
      isLlmEnabled: false,
      llmKeys: [],
      PDFs: [],
      btnLoading: false,
      PDFsDetails: [],
      language1St: undefined,
      language2Nd: undefined,
      getProgress: null,
      progressCompleteReportor: null,
      uploadPDFStyle: {
        marginTop: '5vh',
      },
      uploadPDFImg: null,
      step: PDF_UPLOAD,
      totalPageCount: 0,
      prevFileDetailsLength: 0 
    };
  }

  componentDidMount() {
    /**
     * Initial page loading clear upload redux store
     */
    this.props.predictActions.getMetaDataSelection();
    this.props.predictActions.clearUploadSpace();
    this.props.predictActions.getAllConfigs();

    socketUpload = io.connect(process.env.REACT_APP_API_BASE_URL, { withCredentials: true });

    socketUpload.emit('update_page_data', { page: 'upload' });

    this.update_page_data_interval = setInterval(async function () {
      await socketUpload.emit('update_page_data', { page: 'upload' });
    }, 1000);

    setTimeout(() => {
      clearInterval(this.update_page_data_interval);
      this.update_page_data_interval = setInterval(async function () {
        await socketUpload.emit('update_page_data', { page: 'upload' });
      }, 1000 * 60);
    }, 1000 * 6)

    //stop socket after 8mins
    setTimeout(() => {
      clearInterval(this.update_page_data_interval);
    }, 1000 * 60 * 8);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.PDFsDetails.length > prevState.PDFsDetails.length) {
      this.setState({
        prevFileDetailsLength: this.state.PDFsDetails.length,
      });
    }

  }

  componentWillUnmount() {
    socketUpload.emit('update_page_data', { page: 'upload' });
    clearInterval(this.update_page_data_interval);
    socketUpload.disconnect();
  }

  language1StHanlder = e => {
    let { language2Nd } = this.state;

    if (language2Nd === e.target.value) {
      this.setState({
        language1St: e.target.value,
        language2Nd: 'unselect',
      });
    } else {
      this.setState({
        language1St: e.target.value,
      });
    }
  };

  language2NdHanlder = e => {
    this.setState({
      language2Nd: e.target.value,
    });
  };

  progressCallBackHandler = (progress, getProgressManager) => {
    if (progress === 100) {
      clearInterval(getProgressManager);
      this.props.predictActions.changeValidateStatus({
        isValidatePage: true,
      });
    };
  }

  setGptModalOpen = () => {
    this.setState({
      isGptModalOpen: true
    });
  };

  /**
 * When success upload file
 * call get progress API
 * in every 2 s
 */
  callbackHandler = () => {
    const getProgressManager = setInterval(() => {
      this.props.predictActions.getProgress({
        time: Math.round(new Date().getTime() / 1000),
        callBack: (progress) => this.progressCallBackHandler(progress, getProgressManager),
      });
    }, 3000);
  };

  /**
   * Handle Start function
   */
  handleStart = async config => {
    const { PDFs, PDFsDetails, language1St, language2Nd } = this.state;

    let formData = new FormData();
    PDFsDetails?.forEach(file => {
      formData.append('pdfs', file);
    });

    if (!config.skipped && config.templateId !== undefined) {
      formData.append('orderTemplateId', config.templateId);
    }

    formData.append('language1', language1St);
    formData.append('language2', language2Nd === 'unselect' ? undefined : language2Nd);

    if (this.state.llmKeys && this.state.llmKeys.length > 0) {
      formData.append('llm_enabled', this.state.isLlmEnabled);
      formData.append('llm_keys', this.state.llmKeys);
    }

    if (PDFs.length > 0) {
      this.props.predictActions.uploadPDF({
        formData: formData,
        callBack: this.callbackHandler,
      });
    }

    this.setState({ PDFs: [] });
    this.props.predictActions.setMetadata({ templateChanged: false, newTemplate: config.skipped });
  };

  /**
   * Add PDF File
   * @param {FILE} file PDF File
   * @returns length of PDF File
   */
  addPDF = fileDetails => {
    let { PDFs, PDFsDetails } = this.state;
    fileDetails.map(file => {
      const fd = new FormData();
      fd.append('file', file, file.name);
      PDFs.push(Object.assign(fd));
      return {
        file: file,
        numPages: null,
      };
    });

    this.setState({ PDFs: PDFs });

    this.setState({
      uploadPDFStyle: {
        border: '10px rgba(120, 110, 248, 0.6) solid',
        marginTop: '5vh',
      },
      uploadPDFImg: UploadedStatusImg,
      PDFsDetails: [...PDFsDetails, ...fileDetails],
    });
    return PDFs.length;
  };

  /**
   * Switch view handler
   * @param {string} viewMode view mode
   */
  switchViewHandler = viewMode => {
    if (this.props.configData.data?.length === 0) {
      this.setState({ btnLoading: true });
      PredictActions.setMetadata({ admin: false })
      this.setGptModalOpen();
    } else {
      this.setState({
        step: viewMode,
      });
    }
  };

  updatePageCounterHandler = data => {
    this.setState({
      totalPageCount: data,
    });
  };

  // updatedNonPdfHandler = data => {
  //   if (data?.[0]?.type === 'image/png'|| data?.[0]?.type === 'image/jpg' || data?.[0]?.type === 'image/jpeg') {
  //     return "pdf_limitation";
  //   } else {
  //     return "no_document_selected";
  //   }
  // };

  removePdfHandler = (file, index) => {
    let temp = [...this.state.PDFsDetails];
    let tempObj = temp[index];

    let deletedNoOfPages = tempObj?.numPages || 1;

    if (index > -1) {
      // only splice array when item is found
      temp.splice(index, 1); // 2nd parameter means remove one item only
    }

    let tempPreviewLength = this.state.prevFileDetailsLength;
    if (temp.length === 0) {
      tempPreviewLength = 0;
    }

    this.setState({
      PDFsDetails: temp,
      PDFs: temp,
      totalPageCount: this.state.totalPageCount - deletedNoOfPages,
      prevFileDetailsLength: tempPreviewLength,
    });
  };

  pageUpdateHandler = (index, numPages) => {
    let temp = this.state.PDFsDetails;
    temp[index].numPages = numPages;
    this.setState({
      PDFsDetails: temp,
    });
  };

  render() {
    const { uploadPDF, startPredict, getProgress, getAllDocumentCount } = this.props;
    const { step, language1St, language2Nd, totalPageCount, isGptModalOpen } = this.state;

    if (!uploadPDF.uploadStarted && startPredict.loading) {
      return (
        <>
          <GPTDataSelectDialog open={isGptModalOpen}
            handleUploadStart ={this.handleStart}
            handleStart={(isLlmEnabled, llmKeys) => {
              this.setState({ isGptModalOpen: false, isLlmEnabled, llmKeys })
              // TODO: Continue with the upload process
            }}
            handleSkip={() => {
              this.setState({ isGptModalOpen: false })
              this.handleStart({ skipped: true });
              // TODO: Continue with the upload process
            }} />

          {step === PDF_UPLOAD ? (
            <PdfUpload
              btnLoading={this.state.btnLoading}
              switchViewHandler={this.switchViewHandler}
              id="dragPDF"
              accept={['application/pdf']}
              callBack={this.addPDF}
              fileDetails={this.state.PDFsDetails}
              multiple={true}
              language1StHanlder={this.language1StHanlder}
              language2NdHanlder={this.language2NdHanlder}
              language1St={language1St}
              language2Nd={language2Nd}
              getAllDocumentCount={getAllDocumentCount}
              updatePageCounterHandler={this.updatePageCounterHandler}
              // updatedNonPdfHandler={this.updatedNonPdfHandler}
              removePdfHandler={this.removePdfHandler}
              pageUpdateHandler={this.pageUpdateHandler}
              totalPageCount={totalPageCount}
              prevFileDetailsLength={this.state.prevFileDetailsLength}
              nextRenewalDate={this.props.nextRenewalDate}
            />
          ) : (
            <ConfigScreen handleStart={this.handleStart} setGptModalOpen={this.setGptModalOpen}/>
          )}
        </>
      );
    } else {
      return (
        <UploadLoadingManager
          uploadPDF={uploadPDF}
          getProgress={getProgress}
          totalPageCount={totalPageCount}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    getProgress: state.Predict.getProgress,
    startPredict: state.Predict.startPredict,
    progressCompleteReportor: state.Predict.progressCompleteReportor,
    uploadPDF: state.Predict.uploadPDF,
    getAllDocumentCount: state.Predict.getAllDocumentCount,
    getMetaDataSelection: state.Predict.getMetaDataSelection,
    configData: state.Predict.getAllConfigs,
    nextRenewalDate: state.Auth.userInfo?.data?.data?.user?.monthlyRenewalDate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    predictActions: bindActionCreators(PredictActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Upload));