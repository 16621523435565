import types from "./types";
import { handleActions } from "redux-actions";

const initialState = {
  signIn: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  signUp: {
    loading: true,
    pending: false,
    hasError: false,
    data: [],
    error: {},
  },
  signOut: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  userInfo: {
    data: null,
  },
  changePassword: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  getUserInfo: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  deleteUser: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  updateUser: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  checkEmail: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  resetPassword: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  pricingPlans: {
    loading: true,
    pending: false,
    hasError: false,
    data: null,
    error: {},
  },
  pendingActions: {
    loading: false,
    actions: [],
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.DISPLAY_PRICING_PAGE]: (state, { payload }) => ({
      ...state,
    }),
    // ------------------ SIGN_IN -------------------
    [types.SIGN_IN]: (state, { payload }) => ({
      ...state,
      signIn: {
        ...state.signIn,
        loading: true,
        pending: true,
        hasError: false,
        error: {},
      },
    }),
    [types.SIGN_IN_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.SIGN_IN_FAILED]: (state, { payload }) => {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          loading: false,
          pending: false,
          hasError: true,
          error: { payload },
        },
      };
    },

    // ------------------ SIGN_UP -------------------
    [types.SIGN_UP]: (state, { payload }) => ({
      ...state,
      signUp: {
        ...state.signUp,
        loading: true,
        pending: true,
      },
    }),
    [types.SIGN_UP_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        signUp: {
          ...state.signUp,
          loading: false,
          data: payload,
        },
      };
    },
    [types.SIGN_UP_SPINNER_COMPLETED]: (state, { payload }) => {
      return {
        ...state,
        signUp: {
          ...state.signUp,
          loading: false,
          pending: false,
        },
      };
    },
    [types.SIGN_UP_FAILED]: (state, { payload }) => ({
      ...state,
      signUp: {
        ...state.signUp,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ------------------ GET_PRICING_DATA -------------------
    [types.GET_PRICING_DATA_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        pricingPlans: {
          ...state.pricingPlans,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.GET_PRICING_DATA_FAILED]: (state, { payload }) => ({
      ...state,
      pricingPlans: {
        ...state.pricingPlans,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    [types.SUBSCRIBE_FREE_PLAN_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        userInfo: {
          data: {
            data: {
              user: {
                ...state.userInfo.data.data.user,
                subscription: "free"
              },
            }
          }
        }
      };
    },

    [types.SUBSCRIBE_FREE_PLAN_FAILED]: (state, { payload }) => ({
      ...state,
      userInfo: {
        data: {
          data: {
            user: {
              ...state.userInfo.data.data.user,
              subscription: null
            },
          }
        }
      }
    }),

    [types.PENDING_ACTION_STARTED]: (state, { payload }) => ({
      ...state,
      pendingActions: {
        loading: true,
        actions: [...state.pendingActions.actions, payload],
      },
    }),

    [types.PENDING_ACTION_COMPLETED]: (state, { payload }) => ({
      ...state,
      pendingActions: {
        loading: false,
        actions: state.pendingActions.actions.filter((action) => action !== payload),
      },
    }),

    [types.CONFIRM_SUBSCRIPTION_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          data: {
            data: {
              ...state.userInfo.data.data,
              user: {
                ...state.userInfo.data.data.user,
                subscription: payload.data.user.subscription
              },
            }
          }
        }
      };
    },

    // ------------------ SIGN_OUT -------------------
    [types.SIGN_OUT]: (state, { payload }) => ({
      ...state,
      signOut: {
        ...state.signOut,
        loading: true,
        pending: true,
      },
    }),
    [types.SIGN_OUT_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        signOut: {
          ...state.signOut,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.SIGN_OUT_FAILED]: (state, { payload }) => ({
      ...state,
      signOut: {
        ...state.signOut,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ USER_INFO -------------------
    [types.USER_INFO]: (state, { payload }) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        loading: true,
        pending: true,
      },
    }),
    [types.USER_INFO_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: false,
          pending: false,
          data: payload,
        },
        getUserInfo: {
          ...state.getUserInfo,
          loading: false,
          pending: false,
          data: payload?.data,
        },
      };
    },

    [types.USER_INFO_FAILED]: (state, { payload }) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ CHANGE_PASSWORD -------------------
    [types.CHANGE_PASSWORD]: (state, { payload }) => ({
      ...state,
      changePassword: {
        ...state.changePassword,
        loading: true,
        pending: true,
      },
    }),
    [types.CHANGE_PASSWORD_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.CHANGE_PASSWORD_FAILED]: (state, { payload }) => ({
      ...state,
      changePassword: {
        ...state.changePassword,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ GET_USER_INFO -------------------
    [types.GET_USER_INFO]: (state, { payload }) => ({
      ...state,
      getUserInfo: {
        ...state.getUserInfo,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_USER_INFO_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        getUserInfo: {
          ...state.getUserInfo,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.GET_USER_INFO_FAILED]: (state, { payload }) => ({
      ...state,
      getUserInfo: {
        ...state.getUserInfo,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ------------------ DELETE_USER -------------------
    [types.DELETE_USER]: (state, { payload }) => ({
      ...state,
      deleteUser: {
        ...state.deleteUser,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_USER_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        deleteUser: {
          ...state.deleteUser,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.DELETE_USER_FAILED]: (state, { payload }) => ({
      ...state,
      deleteUser: {
        ...state.deleteUser,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ UPDATE_USER -------------------
    [types.UPDATE_USER]: (state, { payload }) => ({
      ...state,
      updateUser: {
        ...state.updateUser,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_USER_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.UPDATE_USER_FAILED]: (state, { payload }) => ({
      ...state,
      updateUser: {
        ...state.updateUser,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ CHECK_EMAIL -------------------
    [types.CHECK_EMAIL]: (state, { payload }) => ({
      ...state,
      checkEmail: {
        ...state.checkEmail,
        loading: true,
        pending: true,
      },
    }),
    [types.CHECK_EMAIL_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        checkEmail: {
          ...state.checkEmail,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.CHECK_EMAIL_FAILED]: (state, { payload }) => ({
      ...state,
      checkEmail: {
        ...state.checkEmail,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ RESET_PASSWORD -------------------
    [types.RESET_PASSWORD]: (state, { payload }) => ({
      ...state,
      rsesetPassword: {
        ...state.resetPassword,
        loading: true,
        pending: true,
      },
    }),
    [types.RESET_PASSWORD_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.RESET_PASSWORD_FAILED]: (state, { payload }) => ({
      ...state,
      resetPassword: {
        ...state.resetPassword,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ------------------ SET_SESSION -------------------
    [types.SET_SESSION]: (state, { payload }) => ({
      ...state,
      userInfo: {
        data: payload,
      },
    }),

    // ------------------ UPDATE_USER_INFO -------------------
    [types.UPDATE_USER_INFO]: (state, { payload }) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        data: {
          ...state.userInfo.data,
          data: {
            ...state.userInfo.data.data,
            user: {
              ...state.userInfo.data.data.user,
              product_tour_viewed: true,
            },
          },
        },
      },
    }),
  },
  initialState
);
