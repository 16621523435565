import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PredictActions } from "../../modules/validate/ducks";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ToolTip from "../shared/ToolTip";
import { PreviewModal } from "./PreviewModal/PreviewModal";
class PageNavigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderingValue: "",
      isEnabled: false,
      isPreviewModalOpen: false,
    };
    this.tableRef = null;
  }

  closePreviewModal = () => {
    this.setState({
      isPreviewModalOpen: false,
    });
  }

  addOderingValue = () => {
    if (this.state.orderingValue !== "") {
      let headingTemplateDto = {
        heading: this.state.orderingValue,
      };
      this.props.predictActions.addHeadingTemplate({
        headingTemplateDto: headingTemplateDto,
        callback: this.clearInput,
      });
    }
  };

  clearInput = () => {
    this.setState({
      orderingValue: "",
    });
  };

  componentDidMount() {
    this.props.predictActions.getMetaDataSelection();
    window.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = event => {

    if (event.key === "ArrowRight") {
      this.props.pageHandler(1)
    } else if (event.key === "ArrowLeft") {
      this.props.pageHandler(-1)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  render() {
    const { pageHandler, page, match, validateStatus } = this.props;
    const { pageNo = 1 } = match?.params;

    const handleRedirectToDownload = () => {
      redirectToDownloadPage();
    }

    const redirectToDownloadPage = () => {
      this.props.history.push('/results');
      this.setState({
        isEnabled: true
      })
    }

    const openPreviewModal = () => {
      this.setState({
        isPreviewModalOpen: true,
      });
    }

    return (
      <>
        <div
          style={{
            marginTop: "12px",
            display: "flex",
            justifyContent: "center",
          }}
          ref={ref => (this.tableRef = ref)}
        >
          <Button
            className="blue-btn-2"
            style={{
              width: "80px",
              height: "35px",
              textTransform: "none",
              marginRight: "20px",
            }}
            variant="contained"
            disabled={pageNo === "1"}
            onClick={() => pageHandler(-1)}
          >
            <ArrowBackIcon />
          </Button>
          <Button
            className="blue-btn-2"
            style={{
              width: "80px",
              height: "35px",
              textTransform: "none",
            }}
            variant="contained"
            disabled={page?.data?.numberOfPages <= +pageNo}
            onClick={() => pageHandler(1)}
          >
            <ArrowForwardIcon />
          </Button>

          <div>
            {validateStatus.isSelectItem ?
              <Button
                className="blue-btn-4"
                style={{
                  width: "120px",
                  height: "35px",
                  textTransform: "none",
                  marginLeft: "60px",
                }}
                onClick={openPreviewModal}
              >
                Preview
              </Button>
              :
              <ToolTip title="First select Data by clicking the Checkbox" imgUrl={null} isBtn={true} children={
                <span>
                  <Button
                    disabled={true}
                    className="blue-btn-4"
                    style={{
                      width: "120px",
                      height: "35px",
                      textTransform: "none",
                      marginLeft: "60px",
                    }}
                    onClick={openPreviewModal}
                  >
                    Preview
                  </Button>
                </span>
              }
              />
            }
          </div>
        </div>
        <PreviewModal open={this.state.isPreviewModalOpen} handleClose={this.closePreviewModal} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    detections: state.Predict.detections,
    selectedElement: state.Predict.selectedElement,
    selectedTable: state.Predict.selectedTable,
    page: state.Predict.page,
    viewMode: state.Predict.viewMode,
    getMetaDataSelection: state.Predict.getMetaDataSelection,
    validateStatus: state.Predict.validateStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    predictActions: bindActionCreators(PredictActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageNavigator)
);