export const selectTemplateChanged = (state) =>
  state?.Predict?.metadata?.data?.templateChanged;

export const selectNewTemplate = (state) =>
  state?.Predict?.metadata?.data?.newTemplate;

export const selectOverlappingPages = (state) =>
  state?.Predict?.metadata?.data?.overlappingPages;

export const selectNoOfPagesProcessed = (state) =>
  state?.Predict?.metadata?.data?.numberOfPages;

export const selectNoOfFilesProcessed = (state) =>
  state?.Predict?.metadata?.data?.numberOfFiles;

export const isMetadataLoading = (state) => state?.Predict?.metadata?.loading;

export const selectMetadata = (state) => state?.Predict?.metadata.data;
