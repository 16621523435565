import React, { useEffect } from "react";
// import ConfigProvider from 'antd/lib/config-provider'\
import cookies from "js-cookie";
import i18next from "i18next";

//need to get using props
import {DEFAULT_LANGUAGE_CODE } from "../../lngProvider";
// import './feature-i18n.module.less';

import "./i18next";
import LanguageDropdown from "./LanguageDropdown";

export function TranslationManager({ children, hasDropdown = false }) {

  /**
   * WHEN every render
   * get language object using currentLanguageCode
   */

  /**
   * WHEN component mounting
   * update the language code
   */
  useEffect(() => {
    const currentLanguageCode = cookies.get("i18next") || DEFAULT_LANGUAGE_CODE;
    i18next.changeLanguage(currentLanguageCode);
  }, []);

  /**
   * WHEN update current language code
   * maintain the antd locallization
   */
  //   return (
  //     <LocalizationProvider dateAdapter={AdapterDateFns}>
  //       {children}
  //       /LocalizationProvider>
  //   )
  return (
    <>
      {hasDropdown && <LanguageDropdown />}
      {children}
    </>
  );
}
