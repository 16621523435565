import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import DialogContentText from "@mui/material/DialogContentText";
import { BootstrapDialogTitle } from "../../../components/modal/BootstrapDialogTitle";
import TranslateMessage from "../../../components/translateManager/TranslateMessage";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DeleteDialog = ({ open, setOpen, onDelete }) => {
  const onClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    onDelete();
    onClose();
  };

  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle
        className="text-center"
        id="customized-dialog-title"
        onClose={onclose}
      >
        <TranslateMessage translateKey="delete_confirmation_title" />

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </BootstrapDialogTitle>
      <DialogContent>
        <DialogContentText>
          <TranslateMessage translateKey="delete_confirmation_message" />
        </DialogContentText>
      </DialogContent>
      <DialogActions className="justify-content-center pt-0 pb-4">
        <Button
          className="blue-btn-small"
          variant="contained"
          onClick={onConfirm}
        >
          <TranslateMessage translateKey="delete_confirmation_yes" />
        </Button>
        <Button
          className="blue-btn-small with-border"
          variant="contained"
          autoFocus
          onClick={onClose}
        >
          <TranslateMessage translateKey="delete_confirmation_no" />
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default DeleteDialog;
