import { useEffect } from "react";
// import './language-dropdown.module.less'
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import {
  Languages,
  DEFAULT_LANGUAGE_CODE,
  LanguagesOptions,
} from "../../lngProvider";
import i18next from "i18next";
import { MenuItem, Select } from "@mui/material";

export function LanguageDropdown(props) {
  const currentLanguageCode = cookies.get("i18next") || DEFAULT_LANGUAGE_CODE;

  /**
   * WHEN every render
   * get language object using currentLanguageCode
   */
  const currentLanguage = Languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  /**
   * WHEN component mounting
   * update the language code
   */
  useEffect(() => {
    // const currentLanguageCode = (cookies.get('i18next') || 'en') as 'en' | 'ts';
    const currentLanguageCode = cookies.get("i18next") || DEFAULT_LANGUAGE_CODE;
    i18next.changeLanguage(currentLanguageCode);
  }, []);

  /**
   * WHEN update current language code
   * maintain the antd locallization
   */
  useEffect(() => {
    document.body.dir = currentLanguage?.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  /**
   * Language change handler
   * @param language languageCode : en | ar | fr
   */
  const changeLanguage = (e) => {
    cookies.set("i18next", e.target.value);
    i18next.changeLanguage(e.target.value);
  };

  // return <div>English</div>;
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={currentLanguageCode}
      label="Age"
      onChange={changeLanguage}
    >
      {LanguagesOptions.map((lan, i) => {
        return <MenuItem value={lan.value}>{lan.label}</MenuItem>;
      })}
    </Select>
  );
}

export default LanguageDropdown;
