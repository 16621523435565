import { useEffect, useRef } from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import { ElmOperation } from 'util/Constants';
import closeIcon from '../../../../assets/images/icons8-cancel-512.svg';

/**
 * DeleteButton Component.
 *
 * @component
 * @param {Object} selectedLine - The selected line object to be deleted.
 * @param {function} setSelected - Function to set the selected line state.
 * @param {function} setOperation - Function to set the operation on the selected line.
 *
 * @description
 * The `DeleteButton` component displays a button with a delete icon that is associated with a selected line.
 * It takes props such as the `selectedLine` object, a function to set the selected line state (`setSelected`), and a function to set the operation on the selected line (`setOperation`).
 *
 * @returns {JSX.Element} - Returns the JSX representation of the DeleteButton component.
 */
const DeleteButton = ({ selectedLine, setSelected, setOperation }) => {
  const ref = useRef();
  const [image] = useImage(closeIcon);

  useEffect(() => {
    const deleteButton = ref.current;
    if (selectedLine?.attrs?.points) {
      const [x, y] = selectedLine.attrs.points;
      const calculatedX = selectedLine.attrs.x ? x + selectedLine.attrs.x : x;

      deleteButton.position({ x: calculatedX + 4, y });
    }
  }, [selectedLine]);

  /**
   * onClick Event Handler for DeleteButton.
   *
   * @function
   *
   * @description
   * The `onClick` event handler for the DeleteButton component is triggered when the delete button is clicked.
   * It performs the following actions:
   * 1. Removes the delete button element from the DOM using the `ref`.
   * 2. Removes the associated `selectedLine` from the canvas.
   * 3. Clears the selected line state by calling the `setSelected` function with `null`.
   * 4. Sets the operation on the deleted line by calling the `setOperation` function with the operation type `ElmOperation.DELETED` and the attributes of the `selectedLine`.
   *
   * This event handler effectively deletes the selected line from the canvas and updates the relevant state and operations.
   *
   */
  const onClick = () => {
    ref.current.remove();
    selectedLine.remove();
    setSelected(null);
    setOperation(ElmOperation.DELETED, selectedLine.attrs);
  };

  return <Image ref={ref} width={20} height={20} image={image} onClick={onClick} />;
};

export default DeleteButton;
